import React, { FormEvent, useState } from "react";
import { FaSpinner } from "react-icons/fa";
import logo from "../../assets/logo.png";
import api from "../../services/api";
import toast from "react-hot-toast";
import history from "../../services/history";
import { useParams } from "react-router-dom";
import { numberMask } from "../../components/masks";

export default function ChangePassword() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [values, setValues] = useState<any>({});
    let { id }: { id: string } = useParams();

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        setIsLoading(true);
        if (values.password === values.confirmation) {
            if (
                values.password.match(
                    /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])/
                ) &&
                values.password.length > 11
            ) {
                try {
                    await api.post(
                        `sessions/change-password`,
                        {
                            newPassword: values.password,
                            recoveryCode: values.code,
                        },
                        {
                            headers: {
                                Authorization: `Bearer ${id}`,
                            },
                        }
                    );
                    await toast.success("Senha alterada com sucesso!");
                    setTimeout(() => {
                        window.location.replace("/");
                    }, 1000);
                    setIsLoading(false);
                } catch (e: any) {
                    toast.error(e.response.data.message);
                    setIsLoading(false);
                }
            } else {
                toast.error(
                    "A senha deve ter no minimo 12 caracteres, letras minusculas e maiusculas e caracteres especiais."
                );
                setIsLoading(false);
            }
        } else {
            toast.error("As Senhas não coincidem");
            setIsLoading(false);
        }
    }

    return (
        <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-white sm:bg-mainGray">
            <div className="mt-0 sm:mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-4 px-4 shadow-lg sm:rounded-lg sm:px-10 h-screen sm:h-auto">
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                            className="mx-auto h-56 w-auto"
                            src={logo}
                            alt="bureau veritas"
                        />
                        <h2 className="mb-6 text-center text-xl font-bold text-mainGray">
                            Shaping a World of Trust
                        </h2>
                    </div>
                    <div className="text-center text-xl font-bold text-mainGray">
                        Digite o seu email para a recuperação de senha.
                    </div>
                    <form
                        id="loginForm"
                        className="space-y-6"
                        action="#"
                        method="POST"
                        onSubmit={(e) => handleSubmit(e)}
                    >
                        <div>
                            <label
                                htmlFor="code"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Codigo
                            </label>
                            <div className="mt-1">
                                <input
                                    id="code"
                                    name="code"
                                    minLength={4}
                                    maxLength={4}
                                    value={values.code}
                                    onChange={(e) =>
                                        setValues({
                                            ...values,
                                            code: numberMask(e.target.value),
                                        })
                                    }
                                    required
                                    placeholder="0000"
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <label
                                htmlFor="code"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Nova senha
                            </label>
                            <div className="mt-1">
                                <input
                                    id="code"
                                    name="code"
                                    value={values.password}
                                    onChange={(e) =>
                                        setValues({
                                            ...values,
                                            password: e.target.value,
                                        })
                                    }
                                    type="password"
                                    minLength={12}
                                    required
                                    placeholder="Nova senha"
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            <label
                                htmlFor="code"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Confirme a nova senha
                            </label>
                            <div className="mt-1">
                                <input
                                    id="code"
                                    name="code"
                                    value={values.confirmation}
                                    onChange={(e) =>
                                        setValues({
                                            ...values,
                                            confirmation: e.target.value,
                                        })
                                    }
                                    minLength={12}
                                    required
                                    type="password"
                                    placeholder="Confirmação"
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            {isLoading ? (
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  bg-[#4d4d4d] hover:bg-mainDarkRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mainDarkRed"
                                >
                                    <FaSpinner
                                        className="animate-spin mx-auto"
                                        size={23}
                                    />
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="w-full flex justify-center transition-all duration-500 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  bg-[#4d4d4d] hover:bg-mainDarkRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mainDarkRed"
                                >
                                    Enviar
                                </button>
                            )}
                        </div>
                    </form>

                    <div className="mt-6"></div>
                </div>
            </div>
        </div>
    );
}
