import { FaPencilAlt } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useContext, useState } from "react";
import InfoContext from "../../context/InfoContext";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";

import { ParametersListProps } from "../../types/Parameters";
import { ActionModal } from "../ActionModal";
import EmptyList from "../EmptyList";
import "./styles.css";

import {
    MdOutlineAssignmentReturned,
    MdOutlineAssignmentTurnedIn,
    MdOutlineLocationOn,
    MdOutlineCancel,
    MdEdit,
    MdInfoOutline,
    MdOutlineAssignment,
    MdOutlineHighlightOff,
    MdOutlineContactSupport,
} from "react-icons/md";

import Swal from "sweetalert2";

type TableProps = {
    list: ParametersListProps[];
    idProduct: string;
};

export default function ParameterTable({ list, idProduct }: TableProps) {
    const modal = useCustomModal();
    const [itemId, setItemId] = useState<string>("");
    const { reloadData, setReloadData } = useContext(InfoContext);

    async function removeUser(id: string) {
        await api.delete(`parameters/delete/${id}`);
        // await setIsOpen(false);
        Swal.fire({
            icon: "success",
            title: "Sucesso",
            showDenyButton: true,
            showCloseButton: false,
            showConfirmButton: false,
            denyButtonText: `Fechar`,
            text: "Parâmetro deletado com sucesso!",
        });
        setReloadData(!reloadData);
    }

    return (
        <body className="flex items-center justify-center">
            <div className="container">
                <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
                    <thead className="text-white">
                        {list &&
                            list.map((item) => (
                                <tr className="bg-mainGray flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                    <th className="p-3 text-left">ID</th>
                                    <th className="p-3 text-left">Nome</th>
                                    {/* <th className="p-3 text-left">E-mail</th>
                                    <th className="p-3 text-left">Telefone</th> */}
                                    <th className="p-3 text-left w-[110px]">
                                        Ações
                                    </th>
                                </tr>
                            ))}
                    </thead>
                    <tbody className="flex-1 sm:flex-none">
                        {list.length > 0 ? (
                            list.map((item) => (
                                <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 bg-white">
                                    <td className="border-grey-light border p-3">
                                        {item.product_id}
                                    </td>
                                    <td className="border-grey-light border p-3 truncate">
                                        {item.name}
                                    </td>
                                    {/* <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                                        {item.email}
                                    </td>
                                    <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                                        {item.phone}
                                    </td> */}
                                    <td className="border-grey-light border p-3 text-mainDarkRed hover:text-red-600 hover:font-medium">
                                        <div className="flex items-center">
                                            <Link
                                                className="cursor-pointer text-mainGray hover:text-mainDarkRed transition-all duration-500"
                                                to={`/produtos-edit-parametros/${idProduct}/${item.id}`}
                                            >
                                                <MdOutlineAssignment
                                                    size={"1.3em"}
                                                />
                                            </Link>
                                            &nbsp; &nbsp;
                                            <a
                                                className="cursor-pointer text-mainGray hover:text-mainDarkRed transition-all duration-500"
                                                onClick={() => {
                                                    modal.setCustomModal({
                                                        status: true,
                                                        icon: "alert",
                                                        title: "Excluir!",
                                                        text: "Você tem certeza que deseja excluir esse parâmetro?",
                                                        cancelButton:
                                                            "Cancelar",
                                                        confirmButton: "",
                                                    });
                                                    setItemId(
                                                        item.id.toString()
                                                    );
                                                }}
                                            >
                                                <MdOutlineHighlightOff size="1.3em" />
                                            </a>
                                        </div>
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <EmptyList />
                        )}
                    </tbody>
                </table>
            </div>
            <ActionModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                action={removeUser}
                itemId={itemId}
            />
        </body>
    );
}
