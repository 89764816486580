import { MdAddCircle } from "react-icons/md";
import history from "../../services/history";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";

import PrimaryButton from "../../components/Button/PrimaryButton";
import LoadingTable from "../../components/LoadingTable";
import InfoContext from "../../context/InfoContext";
import ProductTable from "../../components/Table/ProductTable";
import { ProductsProps } from "../../types/Products";
import { ParametersListProps } from "../../types/Parameters";
import ParameterTable from "../../components/Table/ParameterTable";
import SecondaryButton from "../../components/Button/SecondaryButton";

import { Pagination } from "../../components/Pagination";

export default function Parameters() {
    const [parameterList, setParameterList] = useState<ParametersListProps[]>();

    const { handleLogOut, user } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [limit, setLimit] = useState(1);
    const [totalRegister, setTotalRegister] = useState(1);
    const [productName, setProductName] = useState("");

    let { id }: { id: string } = useParams();

    useEffect(() => {
        async function getParameters() {
            // setParameterList([]);
            console.log("pagina->", page);
            try {
                console.log(parameterList);
                const { data } = await api.get(
                    `parameters?page=${page}&product_id=${id}`
                );

                console.log("lista->", data.list);
                // setPage(data.currentPage);
                setLastPage(data.lastPage);
                setTotalRegister(data.totalRegister);
                //  ${parameterList !== [] ? parameterList[0].name : ""}
                console.log(
                    "data",
                    data.list.length > 0 ? "array cheio" : "nao"
                );
                setParameterList(
                    data.list
                        ? data.list
                        : [{ name: "sem parametro cadastrado" }]
                );
            } catch {
                handleLogOut();
            }
        }

        getParameters();
    }, [id, reloadData, page]);

    useEffect(() => {
        async function getProduct() {
            if (id) {
                const { data } = await api.get(`products/${id}`);
                console.log(data);
                setProductName(data.name);
                // setValue("name", data.name);
                // setValue("type_piece", data.type_piece);
                // setValue("type_tissue", data.type_tissue);
                // setValue("category", data.category);
            }
        }

        getProduct();
    }, [id, reloadData]);

    console.log("parameterList", parameterList);
    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl">
                    {
                        //@ts-ignore
                        `Parâmetros  - ${productName}`
                    }
                </h1>
                <div className="flex gap-3">
                    <div className="bg-white rounded-md">
                        <SecondaryButton
                            title="Voltar"
                            onClick={() => history.goBack()}
                        />
                    </div>

                    <Link to={`/produtos-add-parametros/${id}`}>
                        <PrimaryButton title="Cadastrar" />
                    </Link>
                </div>
            </div>

            {parameterList ? (
                <>
                    <ParameterTable list={parameterList} idProduct={id} />
                    <Pagination
                        totalPage={lastPage}
                        page={page}
                        setPage={setPage}
                        path={`parameters`}
                    />
                </>
            ) : (
                <LoadingTable />
            )}
        </div>
    );
}
