import { MdAddCircle } from "react-icons/md";
import history from "../../services/history";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";

import PrimaryButton from "../../components/Button/PrimaryButton";
import LoadingTable from "../../components/LoadingTable";
import InfoContext from "../../context/InfoContext";

import ClothingSetTable from "../../components/Table/ClothingSetTable";
import { ClothingSetListProps } from "../../types/ClothingSet";
import { Pagination } from "../../components/Pagination";

export default function ClothingSet() {
    const [clothingSetList, setClothingSetList] =
        useState<ClothingSetListProps[]>();

    const { handleLogOut, user } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [limit, setLimit] = useState(1);
    const [totalRegister, setTotalRegister] = useState(1);

    let { id }: { id: string } = useParams();

    const getClothingSet = useCallback(async () => {
        // setClothingSetList([]);
        try {
            const { data } = await api.get(`clothingsets?page=${page}&limit=7`);

            setPage(data.currentPage);
            setLastPage(data.lastPage);
            setTotalRegister(data.totalRegister);
            setClothingSetList(data.list);
            setIsLoading(true);
        } catch {
            handleLogOut();
        }
    }, [page]);

    useEffect(() => {
        getClothingSet();
    }, [id, reloadData, page]);

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl">
                    Conjuntos ou Kit de Produtos
                </h1>
                <Link to="/conjuntos-produtos-cadastrar">
                    <PrimaryButton title="Cadastrar" />
                </Link>
            </div>

            {isLoading ? (
                <>
                    {clothingSetList ? (
                        <>
                            <ClothingSetTable list={clothingSetList} />
                            {clothingSetList.length ? (
                                <Pagination
                                    totalPage={lastPage}
                                    page={page}
                                    setPage={setPage}
                                    path={`clothingsets`}
                                />
                            ) : (
                                ""
                            )}
                        </>
                    ) : (
                        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="py-16">
                                <div className="text-center">
                                    <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide"></p>
                                    <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                        No momento não temos conjuntos ou kit de
                                        produtos cadastrados
                                    </h1>
                                    <p className="mt-2 text-base text-gray-500"></p>
                                </div>
                            </div>
                        </main>
                    )}
                </>
            ) : (
                <LoadingTable />
            )}
        </div>
    );
}
