import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import { useState } from "react";

interface RegistrationProps {
    id: number;
    name: string;
    // type_piece: string;
    // type_tissue: string;
    created_at: string;
    updated_at: string;
}

const schema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    // type_piece: yup.string().required("Campo obrigatório"),
    // type_tissue: yup.string().required("Campo obrigatório"),
});

export default function ClothingSetRegistration() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const modal = useCustomModal();

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<RegistrationProps>({
        resolver: yupResolver(schema),
    });

    async function handleRegisterProduct(data: RegistrationProps) {
        setIsLoading(true);
        const dataRegistration = {
            name: data.name,
            // type_piece: data.type_piece,
            // type_tissue: data.type_tissue,
            product: [],
        };
        try {
            const response = await api.post(
                "clothingsets/add",
                dataRegistration
            );
            if (response.data.status === "Error") {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível realizar seu cadastro",
                    text: response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
            } else {
                modal.setCustomModal({
                    status: true,
                    icon: "success",
                    title: "Conjunto ou Kit cadastrado com sucesso!",
                    text: "Agora você pode acessá-lo na área de conjunto ou kit de produtos",
                    cancelButton: "",
                    confirmButton: "Ok",
                });
            }
            setIsLoading(false);
        } catch (e: any) {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Um erro inesperado aconteceu",
                text: e.response.data.message,
                cancelButton: "",
                confirmButton: "",
            });
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Cadastro de Conjunto ou Kit de Produtos
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Área de cadastro de conjunto ou kit de produtos
                        </p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form
                            action="#"
                            method="POST"
                            id="productRegisterForm"
                            onSubmit={handleSubmit(handleRegisterProduct)}
                        >
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Nome do Conjunto ou Kit de Produtos
                                    </label>
                                    <input
                                        {...register("name")}
                                        type="text"
                                        name="name"
                                        id="name"
                                        autoComplete="given-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.name && (
                                        <span className="text-mainDarkRed">
                                            {errors.name.message}
                                        </span>
                                    )}
                                </div>
                                {/* <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="type_piece"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Tipo de peça
                                    </label>
                                    <input
                                        {...register("type_piece")}
                                        type="text"
                                        name="type_piece"
                                        id="type_piece"
                                        autoComplete="given-type_piece"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.type_piece && (
                                        <span className="text-mainDarkRed">
                                            {errors.type_piece.message}
                                        </span>
                                    )}
                                </div> */}
                                {/* <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="type_tissue"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Tipo de tecido
                                    </label>
                                    <input
                                        {...register("type_tissue")}
                                        type="text"
                                        name="type_tissue"
                                        id="type_tissue"
                                        autoComplete="given-type_tissue"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.type_tissue && (
                                        <span className="text-mainDarkRed">
                                            {errors.type_tissue.message}
                                        </span>
                                    )}
                                </div> */}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-6 w-full flex justify-center md:justify-end gap-3">
                    <div
                        className="w-full md:w-32"
                        onClick={() => history.goBack()}
                    >
                        <SecondaryButton title="Voltar" />
                    </div>
                    <div className="w-full md:w-32">
                        <PrimaryButton
                            title="Salvar"
                            form="productRegisterForm"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
            <AlertModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                confirmButton={modal.customModal.confirmButton}
                path="/conjuntos-produtos"
            />
        </>
    );
}
