import React, { FormEvent, useState } from "react";
import logo from "../../assets/logo.png";
import { FaSpinner } from "react-icons/fa";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import api from "../../services/api";
import toast from "react-hot-toast";
import history from "../../services/history";

export default function ChangePass() {
    const [email, setEmail] = useState<string>("");
    const [isLoading, setIsLoading] = useState<boolean>(false);

    async function handleSubmit(e: FormEvent) {
        e.preventDefault();
        setIsLoading(true);
        try {
            await api.post(`sessions/redefine`, {
                email: email,
            });
            toast.success(
                "Solicitação de recuperação de senha enviada, por favor verifique seu e-mail."
            );
            setIsLoading(false);
            history.goBack();
        } catch (e: any) {
            toast.error(e.response.data.message);
            setIsLoading(false);
        }
    }

    return (
        <div className="min-h-screen flex flex-col justify-center py-12 sm:px-6 lg:px-8 bg-white sm:bg-mainGray">
            <div className="mt-0 sm:mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                <div className="bg-white py-4 px-4 shadow-lg sm:rounded-lg sm:px-10 h-screen sm:h-auto">
                    <div className="absolute w-full flex">
                        <div
                            className="text-mainGray hover:text-mainDarkRed transition-all duration-500 cursor-pointer"
                            onClick={() => history.goBack()}
                        >
                            <IoArrowBackCircleOutline size="1.8em" />
                        </div>
                    </div>
                    <div className="sm:mx-auto sm:w-full sm:max-w-md">
                        <img
                            className="mx-auto h-56 w-auto"
                            src={logo}
                            alt="bureau veritas"
                        />
                        <h2 className="mb-6 text-center text-xl font-bold text-mainGray">
                            Shaping a World of Trust
                        </h2>
                    </div>
                    <div className="text-center text-xl font-bold text-mainGray">
                        Digite o seu email para a recuperação de senha.
                    </div>
                    <form
                        id="loginForm"
                        className="space-y-6"
                        action="#"
                        method="POST"
                        onSubmit={(e) => handleSubmit(e)}
                    >
                        <div>
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                            >
                                E-mail
                            </label>
                            <div className="mt-1">
                                <input
                                    id="email"
                                    name="email"
                                    type="email"
                                    autoComplete="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    required
                                    placeholder="Digite seu e-mail"
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                />
                            </div>
                        </div>
                        <div>
                            {isLoading ? (
                                <button
                                    type="submit"
                                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  bg-[#4d4d4d] hover:bg-mainDarkRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mainDarkRed"
                                >
                                    <FaSpinner
                                        className="animate-spin mx-auto"
                                        size={23}
                                    />
                                </button>
                            ) : (
                                <button
                                    type="submit"
                                    className="w-full flex justify-center transition-all duration-500 py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  bg-[#4d4d4d] hover:bg-mainDarkRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mainDarkRed"
                                >
                                    Enviar
                                </button>
                            )}
                        </div>
                    </form>

                    <div className="mt-6"></div>
                </div>
            </div>
        </div>
    );
}
