import { MdAddCircle } from "react-icons/md";
import history from "../../services/history";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";

import PrimaryButton from "../../components/Button/PrimaryButton";
import LoadingTable from "../../components/LoadingTable";
import InfoContext from "../../context/InfoContext";

import ClothingProductTable from "../../components/Table/ClothingProductTable";
import { ClothingSetProductProps } from "../../types/ClothingSet";
import { ProductsProps } from "../../types/Products";
import SecondaryButton from "../../components/Button/SecondaryButton";

export default function ClothingSetProducts() {
    const [productList, setProductList] = useState<ClothingSetProductProps[]>(); //sem nada ainda

    const { handleLogOut, user } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);

    let { id }: { id: string } = useParams();

    useEffect(() => {
        async function getProduts() {
            // setProductList([]);  //removido para poder usar o load
            try {
                console.log(productList);
                const { data } = await api.get(`clothingsets/${id}`);

                setProductList(data.productSetClothingSets);
            } catch {
                handleLogOut();
            }
        }

        getProduts();
    }, [id, reloadData]);

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl">Produtos</h1>
                <div className="w-32" onClick={() => history.goBack()}>
                    <PrimaryButton title="Voltar" />
                </div>
            </div>

            {productList ? (
                <ClothingProductTable list={productList} />
            ) : (
                <LoadingTable />
            )}
        </div>
    );
}
