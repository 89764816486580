import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import InfoContext from "../../context/InfoContext";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";
import { ProductsProps } from "../../types/Products";
import { QuestionChecklistProps } from "../../types/QuestionChecklist";
import { ActionModal } from "../ActionModal";
import "./styles.css";
import { FaSpinner, FaFilePdf, FaInfoCircle, FaFileAlt } from "react-icons/fa";

import {
    MdOutlineAssignmentReturned,
    MdOutlineAssignmentTurnedIn,
    MdOutlineLocationOn,
    MdOutlineCancel,
    MdEdit,
    MdInfoOutline,
    MdOutlineAssignment,
    MdOutlineHighlightOff,
    MdOutlineContactSupport,
} from "react-icons/md";

import Swal from "sweetalert2";

type TableProps = {
    list: QuestionChecklistProps[];
};

export default function QuestionsTable({ list }: TableProps) {
    const modal = useCustomModal();
    const [itemId, setItemId] = useState<string>("");
    const { reloadData, setReloadData } = useContext(InfoContext);

    async function removeUser(id: string) {
        await api.delete(`groups/${id}`);
        // await setIsOpen(false);
        Swal.fire({
            icon: "success",
            title: "Sucesso",
            showDenyButton: true,
            showCloseButton: false,
            showConfirmButton: false,
            denyButtonText: `Fechar`,
            text: "Grupo deletado com sucesso!",
        });
        setReloadData(!reloadData);
    }

    return (
        <>
            <body className="flex items-center justify-center">
                <div className="container hidden sm:block">
                    <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
                        <thead className="text-white">
                            {list &&
                                list.map((item) => (
                                    <tr className="bg-mainGray flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                        <th className="p-3 text-left">Grupo</th>
                                        <th className="p-3 text-left">
                                            Descrição
                                        </th>
                                        <th className="p-3 text-left w-[110px]">
                                            Ações
                                        </th>
                                    </tr>
                                ))}
                        </thead>
                        <tbody className="flex-1 sm:flex-none">
                            {list &&
                                list.map((item) => (
                                    <tr className="flex flex-col flex-no border wrap sm:table-row mb-2 sm:mb-0 bg-white">
                                        <td className="border-grey-light border hover:bg-gray-100 p-3">
                                            {item.name}
                                        </td>
                                        <td className="border-grey-light border hover:bg-gray-100 p-3">
                                            {item.description}
                                        </td>
                                        <td className="flex items-center justify-center ">
                                            <Link
                                                className="cursor-pointer ml-2"
                                                to={`/extraQuestionsEdit/${item.id}`}
                                            >
                                                <MdOutlineAssignment
                                                    size={"1.3em"}
                                                    className="mt-[1px] text-mainGray hover:text-mainDarkRed hover:font-medium transition-all duration-500"
                                                />
                                            </Link>
                                            <div
                                                className=" p-3 text-mainGray hover:text-mainDarkRed hover:font-medium cursor-pointer transition-all duration-500"
                                                onClick={() => {
                                                    modal.setCustomModal({
                                                        status: true,
                                                        icon: "alert",
                                                        title: "Excluir!",
                                                        text: "Você tem certeza que deseja excluir esse grupo de perguntas?",
                                                        cancelButton:
                                                            "Cancelar",
                                                        confirmButton: "",
                                                    });
                                                    setItemId(
                                                        item.id.toString()
                                                    );
                                                }}
                                            >
                                                <MdOutlineHighlightOff
                                                    size={"1.3em"}
                                                />
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <ActionModal
                    type={modal.customModal.icon}
                    title={modal.customModal.title}
                    description={modal.customModal.text}
                    isOpen={modal.customModal.status}
                    setIsOpen={modal.handleCustomModalClose}
                    action={removeUser}
                    itemId={itemId}
                />
            </body>
            <div>
                {list &&
                    list.map((item: any) => (
                        <div className="flex justify-center h-full rounded-xl border border-gray-200 bg-white sm:hidden mt-4 overflow-hidden ">
                            <div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Grupo
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Descrição
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Ações
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2 overflow-y-auto">
                                    {item.name}
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2 overflow-y-auto">
                                    {item.description}
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    <Link
                                        className="cursor-pointer ml-2"
                                        to={`/extraQuestionsEdit/${item.id}`}
                                    >
                                        <MdOutlineAssignment
                                            size={"1.3em"}
                                            className="mt-[1px] text-mainGray hover:text-mainDarkRed hover:font-medium"
                                        />
                                    </Link>
                                    <div
                                        className=" hover:bg-gray-100 p-3 text-mainGray hover:text-mainDarkRed hover:font-medium cursor-pointer"
                                        onClick={() => {
                                            modal.setCustomModal({
                                                status: true,
                                                icon: "alert",
                                                title: "Excluir!",
                                                text: "Você tem certeza que deseja excluir esse grupo de perguntas?",
                                                cancelButton: "Cancelar",
                                                confirmButton: "",
                                            });
                                            setItemId(item.id.toString());
                                        }}
                                    >
                                        <MdOutlineHighlightOff size={"1.3em"} />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                <ActionModal
                    type={modal.customModal.icon}
                    title={modal.customModal.title}
                    description={modal.customModal.text}
                    isOpen={modal.customModal.status}
                    setIsOpen={modal.handleCustomModalClose}
                    action={removeUser}
                    itemId={itemId}
                />
            </div>
        </>
    );
}
