import { Toaster } from "react-hot-toast";
import "./App.css";
import { AuthProvider } from "./context/AuthContext";
import { InfoContextProvider } from "./context/InfoContext";
import Routes from "./routes";

function App() {
    return (
        <AuthProvider>
            <InfoContextProvider>
                <Toaster position="top-center" />
                <Routes />
            </InfoContextProvider>
        </AuthProvider>
    );
}

export default App;
