import { MdAddCircle } from "react-icons/md";
import history from "../../services/history";
import { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";

import PrimaryButton from "../../components/Button/PrimaryButton";
import LoadingTable from "../../components/LoadingTable";
import InfoContext from "../../context/InfoContext";

import { ParametersListProps } from "../../types/Parameters";
import ParameterTable from "../../components/Table/ParameterTable";
import SecondaryButton from "../../components/Button/SecondaryButton";
import DefectTable from "../../components/Table/DefectTable";
import { DefectProps } from "../../types/Defects";
import { Pagination } from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";

export default function Defects() {
    const [defectList, setDefectList] = useState<DefectProps[]>();

    const { handleLogOut, user } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [limit, setLimit] = useState(1);
    const [totalRegister, setTotalRegister] = useState(1);
    const [search, setSearch] = useState<string>("");

    let { id }: { id: string } = useParams();

    useEffect(() => {
        async function getDefects() {
            setIsLoading(false);
            try {
                const { data } = await api.get(
                    `defects?query=${search}&page=${page}`
                );

                setPage(data.currentPage);
                setLastPage(data.lastPage);
                setTotalRegister(data.totalRegister);
                setDefectList(data.list);
                setIsLoading(true);
            } catch {
                console.log("oia");
                // handleLogOut();
            }
        }
        getDefects();
    }, [id, reloadData, page, search]);

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl">Defeitos</h1>

                <Link to={`/defeitos-registrar`}>
                    <PrimaryButton title="Cadastrar" />
                </Link>
            </div>
            <div className="my-4 w-full">
                <SearchBar search={search} setSearch={setSearch} />
            </div>

            {isLoading ? (
                <>
                    {defectList ? (
                        <>
                            <DefectTable list={defectList} />
                            <Pagination
                                totalPage={lastPage}
                                page={page}
                                setPage={setPage}
                                path={`defects`}
                            />
                        </>
                    ) : (
                        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="py-16">
                                <div className="text-center">
                                    <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide"></p>
                                    <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                        No momento não temos defeitos
                                        cadastradas
                                    </h1>
                                    <p className="mt-2 text-base text-gray-500"></p>
                                </div>
                            </div>
                        </main>
                    )}
                </>
            ) : (
                <LoadingTable />
            )}
        </div>
    );
}
