import { useContext, useState } from "react";
import { Link } from "react-router-dom";
import InfoContext from "../../context/InfoContext";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";
import { ProductsProps } from "../../types/Products";
import { ActionModal } from "../ActionModal";
import "./styles.css";

import Swal from "sweetalert2";

import {
    MdOutlineAssignmentReturned,
    MdOutlineAssignmentTurnedIn,
    MdOutlineLocationOn,
    MdOutlineCancel,
    MdEdit,
    MdInfoOutline,
    MdOutlineAssignment,
    MdOutlineHighlightOff,
    MdOutlineContactSupport,
} from "react-icons/md";

import { BiErrorAlt } from "react-icons/bi";

import { RiTShirt2Fill } from "react-icons/ri";

import { AiFillDelete } from "react-icons/ai";

type TableProps = {
    list: ProductsProps[];
};

export default function ProductTable({ list }: TableProps) {
    const modal = useCustomModal();
    const [itemId, setItemId] = useState<string>("");
    const { reloadData, setReloadData } = useContext(InfoContext);

    async function removeUser(id: string) {
        await api.delete(`products/delete/${id}`);
        // await setIsOpen(false);
        Swal.fire({
            icon: "success",
            title: "Sucesso",
            showDenyButton: true,
            showCloseButton: false,
            showConfirmButton: false,
            denyButtonText: `Fechar`,
            text: "Produto deletado com sucesso!",
        });
        setReloadData(!reloadData);
    }

    return (
        <>
            <body className="flex items-center justify-center">
                <div className="container hidden sm:block">
                    <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
                        <thead className="text-white">
                            {list &&
                                list.map((item) => (
                                    <tr className="bg-mainGray flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                        <th className="p-3 text-left">Nome</th>
                                        <th className="p-3 text-left">
                                            Classificação
                                        </th>
                                        <th className="p-3 text-left">
                                            Tecido
                                        </th>
                                        <th className="p-3 text-left">
                                            Categoria
                                        </th>
                                        {/* <th className="p-3 text-left w-[110px]">
                                            Adicionar
                                        </th> */}
                                        <th className="p-3 text-left w-[110px]">
                                            Defeitos
                                        </th>
                                        <th className="p-3 text-left w-[110px]">
                                            Parâmetros
                                        </th>
                                        <th className="p-3 text-left w-[110px]">
                                            Ações
                                        </th>
                                    </tr>
                                ))}
                        </thead>
                        <tbody className="flex-1 sm:flex-none">
                            {list &&
                                list.map((item) => (
                                    <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 bg-white">
                                        <td className="border-grey-light border hover:bg-gray-100 p-3">
                                            {item.name}
                                        </td>
                                        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                                            {item.type_piece}
                                        </td>
                                        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                                            {item.type_tissue}
                                        </td>
                                        <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                                            {item.category}
                                        </td>
                                        {/* <td className="border-grey-light border hover:bg-gray-100 p-3 text-mainDarkRed hover:text-red-600 hover:font-medium cursor-pointer">
                                            <Link
                                                to={`questionchecklists?product_id=${item.id}`}
                                            >
                                                Perguntas
                                            </Link>
                                        </td> */}
                                        <td className="border-grey-light border p-3 text-mainGray hover:text-mainDarkRed transition-all duration-500 hover:font-medium cursor-pointer">
                                            <Link
                                                to={`/produtos-defeitos/${item.id}`}
                                            >
                                                <BiErrorAlt size="1.3em" />
                                            </Link>
                                        </td>
                                        <td className="border-grey-light border p-3 text-mainGray hover:text-mainDarkRed transition-all duration-500 hover:font-medium cursor-pointer">
                                            <Link
                                                to={`/produtos-parametros/${item.id}`}
                                            >
                                                <RiTShirt2Fill size="1.3em" />
                                            </Link>
                                        </td>
                                        <td className="border-grey-light border  p-3 transition-all duration-500 hover:font-medium ">
                                            <div className="flex items-center">
                                                <Link
                                                    className="cursor-pointer text-mainGray hover:text-mainDarkRed transition-all duration-500"
                                                    to={`/produtos-editar/${item.id}`}
                                                >
                                                    <MdOutlineAssignment
                                                        size={"1.3em"}
                                                    />
                                                </Link>
                                                &nbsp; &nbsp;
                                                <a
                                                    className="cursor-pointer hover:text-mainDarkRed text-mainGray transition-all duration-500"
                                                    onClick={() => {
                                                        modal.setCustomModal({
                                                            status: true,
                                                            icon: "alert",
                                                            title: "Excluir!",
                                                            text: "Você tem certeza que deseja excluir esse produto?",
                                                            cancelButton:
                                                                "Cancelar",
                                                            confirmButton: "",
                                                        });
                                                        setItemId(
                                                            item.id.toString()
                                                        );
                                                    }}
                                                >
                                                    <MdOutlineHighlightOff
                                                        size={"1.3em"}
                                                    />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                </div>
                <ActionModal
                    type={modal.customModal.icon}
                    title={modal.customModal.title}
                    description={modal.customModal.text}
                    isOpen={modal.customModal.status}
                    setIsOpen={modal.handleCustomModalClose}
                    action={removeUser}
                    itemId={itemId}
                />
            </body>
            <div>
                {list &&
                    list.map((item) => (
                        <div className="flex justify-center h-full rounded-xl border border-gray-200 bg-white sm:hidden mt-4 overflow-hidden ">
                            <div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Nome
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Classificação
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Tecido
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Categoria
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Defeitos
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Parâmetros
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Ações
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    {item.name}
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    {item.type_piece}
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    {item.type_tissue}
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    {item.category}
                                </div>
                                <div className="border-grey-light text-mainGray border-t h-12 flex justify-start items-center px-2">
                                    <Link to={`/produtos-defeitos/${item.id}`}>
                                        <BiErrorAlt size="1.3em" />
                                    </Link>
                                </div>
                                <div className="border-grey-light text-mainGray border-t h-12 flex justify-start items-center px-2">
                                    <Link
                                        to={`/produtos-parametros/${item.id}`}
                                    >
                                        <RiTShirt2Fill size="1.3em" />
                                    </Link>
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    <div className="flex items-center">
                                        <Link
                                            className="cursor-pointer text-mainGray hover:text-mainDarkRed transition-all duration-500"
                                            to={`/produtos-editar/${item.id}`}
                                        >
                                            <MdOutlineAssignment
                                                size={"1.3em"}
                                            />
                                        </Link>
                                        &nbsp; &nbsp;
                                        <a
                                            className="cursor-pointer hover:text-mainDarkRed text-mainGray transition-all duration-500"
                                            onClick={() => {
                                                modal.setCustomModal({
                                                    status: true,
                                                    icon: "alert",
                                                    title: "Excluir!",
                                                    text: "Você tem certeza que deseja excluir esse produto?",
                                                    cancelButton: "Cancelar",
                                                    confirmButton: "",
                                                });
                                                setItemId(item.id.toString());
                                            }}
                                        >
                                            <MdOutlineHighlightOff
                                                size={"1.3em"}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
}
