import logo from "../../assets/logo.png";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import * as yup from "yup";
import { Context } from "../../context/AuthContext";

import { FaSpinner } from "react-icons/fa";
import Footer from "../../components/Footer";

export default function Login() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    // add info
    interface IFormInputs {
        email: string;
        password: string;
    }

    const schema = yup.object().shape({
        email: yup.string().required("E-mail obrigatório"),
        // .min(14, "Mínimo 14 caracteres")
        // .max(18, "Máximo 18 caracteres"),
        password: yup.string().required("Senha obrigatória"),
        // .min(6, "Mínimo 6 digitos"),
    });

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm<IFormInputs>({
        resolver: yupResolver(schema),
    });

    const { handleLogin } = useContext(Context);
    async function login(data: IFormInputs) {
        console.log(data);
        setIsLoading(true);
        await handleLogin(data);
        await setIsLoading(false);
    }

    return (
        <>
            {/*
          This example requires updating your template:

          ```
          <html class="h-full bg-gray-50">
          <body class="h-full">
          ```
        */}
            <div className="flex flex-col justify-center min-h-screen py-12 bg-white sm:px-6 lg:px-8 sm:bg-mainGray">
                <div className="mt-0 sm:mt-8 sm:mx-auto sm:w-full sm:max-w-md">
                    <div className="h-screen px-4 py-4 bg-white shadow-lg sm:rounded-lg sm:px-10 sm:h-auto">
                        <div className="sm:mx-auto sm:w-full sm:max-w-md">
                            <img
                                className="w-auto h-56 mx-auto"
                                src={logo}
                                alt="bureau veritas"
                            />
                            <h2 className="mb-6 text-xl font-bold text-center text-mainGray">
                                Shaping a World of Trust
                            </h2>
                        </div>
                        <form
                            id="loginForm"
                            className="space-y-6"
                            action="#"
                            method="POST"
                            onSubmit={handleSubmit(login)}
                        >
                            <div>
                                <label
                                    htmlFor="email"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    E-mail
                                </label>
                                <div className="mt-1">
                                    <input
                                        {...register("email")}
                                        id="email"
                                        name="email"
                                        type="email"
                                        autoComplete="email"
                                        // required
                                        placeholder="Digite seu e-mail"
                                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                </div>
                                {errors.email && (
                                    <span className="text-mainDarkRed">
                                        {errors.email.message}
                                    </span>
                                )}
                            </div>

                            <div>
                                <label
                                    htmlFor="password"
                                    className="block text-sm font-medium text-gray-700"
                                >
                                    Senha
                                </label>
                                <div className="mt-1">
                                    <input
                                        {...register("password")}
                                        id="password"
                                        name="password"
                                        type="password"
                                        autoComplete="current-password"
                                        // required
                                        placeholder="Digite sua senha"
                                        className="block w-full px-3 py-2 placeholder-gray-400 border border-gray-300 rounded-md shadow-sm appearance-none focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                </div>
                                {errors.password && (
                                    <span className="text-mainDarkRed">
                                        {errors.password.message}
                                    </span>
                                )}
                            </div>

                            <div className="flex items-center justify-end">
                                <div className="text-sm">
                                    <a
                                        href="changepass"
                                        className="font-medium text-mainDarkRed hover:text-mainGray"
                                    >
                                        Esqueceu sua senha?
                                    </a>
                                </div>
                            </div>

                            <div>
                                {isLoading ? (
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  bg-[#4d4d4d] hover:bg-mainDarkRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mainDarkRed"
                                    >
                                        <FaSpinner
                                            className="mx-auto animate-spin"
                                            size={23}
                                        />
                                    </button>
                                ) : (
                                    <button
                                        type="submit"
                                        className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  bg-[#4d4d4d] hover:bg-mainDarkRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mainDarkRed"
                                    >
                                        Entrar
                                    </button>
                                )}
                            </div>
                        </form>

                        <div className="mt-6"></div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    );
}
