import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import { useCallback, useContext, useEffect, useState } from "react";
import { DefectProps } from "../../types/Defects";
import InfoContext from "../../context/InfoContext";
import { Context } from "../../context/AuthContext";
import { Link, useParams } from "react-router-dom";
import { ProductsProps } from "../../types/Products";
import { Pagination } from "../../components/Pagination";
import { BsPlusSquare } from "react-icons/bs";
import { OutputFileType } from "typescript";

interface RegistrationProps {
    fullname: string;
    email: string;
    password: string;
    confirmPassword: string;
    department: string;
    cargo: string;

    defect_id: string;
    product_id: string;
}

export default function AddProductToClothing() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [selectedProducts, setSelectedProps] = useState<any[]>([]);

    const modal = useCustomModal();

    let clothingProduct: any[] = [];
    let deleteclothingProduct: any[] = [];

    async function handleRegisterProductToClothingSet(
        product: [],
        deleteProduct: []
    ) {
        setIsLoading(true);

        let output = [...product, ...selectedProducts];
        output = output.filter(
            (item: any) =>
                !deleteProduct.map((item: any) => item.id).includes(item.id)
        );

        const outputSelected: any[] = [];
        output.forEach((item: any) => {
            if (
                !outputSelected
                    .map((selected: any) => selected.id)
                    .includes(item.id)
            ) {
                outputSelected.push(item);
            }
        });

        const dataRegistration = {
            name: name,
            products: outputSelected,
            products_delete: deleteProduct,
        };
        try {
            const response = await api.put(
                `clothingsets/update/${id}`,
                dataRegistration
            );
            if (response.data.status === "Error") {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível realizar seu cadastro",
                    text: response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
            } else {
                modal.setCustomModal({
                    status: true,
                    icon: "success",
                    title: "Produtos vinculados com sucesso!",
                    text: "Agora seus produtos estão vinculados a este conjunto ou kit",
                    cancelButton: "",
                    confirmButton: "Ok",
                });
            }
            setIsLoading(false);
        } catch {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Um erro inesperado aconteceu",
                text: "Não foi possível realizar o cadastro",
                cancelButton: "",
                confirmButton: "",
            });
            setIsLoading(false);
        }
    }
    const { handleLogOut, user } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);

    let { id }: { id: string } = useParams();

    const [productList, setProductList] = useState<ProductsProps[]>([]);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [limit, setLimit] = useState(1);
    const [totalRegister, setTotalRegister] = useState(1);
    const [name, setName] = useState<string>("");

    const getProduts = useCallback(async () => {
        setProductList([]);
        try {
            const { data } = await api.get(`products?limit=15&page=${page}`);
            setPage(data.currentPage);
            setLastPage(data.lastPage);
            setTotalRegister(data.totalRegister);
            setProductList([...productList, ...data.list]);
        } catch {
            handleLogOut();
        }
    }, [page]);

    useEffect(() => {
        getProduts();
    }, [id, reloadData, page]);

    const productConnectedToSetClothing = useCallback(async () => {
        try {
            const { data } = await api.get(`clothingsets/${id}`);
            setName(data.name);

            console.log("produtos ->", data.productSetClothingSets);

            const inputData: any = [];
            data.productSetClothingSets.forEach((valor: any) => {
                setValue(String(valor.product_id), true);
                inputData.push({ id: valor.product_id });
            });
            setSelectedProps([...selectedProducts, ...inputData]);
        } catch (err: any) {
            console.log(err);
            // handleLogOut();
        }
    }, [id]);

    useEffect(() => {
        productConnectedToSetClothing();
    }, [id]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<any>();

    return (
        <>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Vincular produtos à conjunto ou kit
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Área para vincular produtos à conjunto ou kit
                        </p>
                        <div className="mt-4">
                            <label
                                htmlFor="email"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Nome
                            </label>
                            <div className="mt-1">
                                <input
                                    id="name"
                                    name="name"
                                    type="name"
                                    autoComplete="name"
                                    value={name}
                                    onChange={(e) => setName(e.target.value)}
                                    required
                                    placeholder="Nome do produto"
                                    className="appearance-none block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm placeholder-gray-400 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                />
                            </div>
                        </div>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form action="#" method="POST" id="userRegisterForm">
                            <div className="block text-lg font-bold text-gray-700">
                                Lista de produtos
                            </div>
                            <div className="flex justify-start gap-x-14 gap-y-4 flex-wrap relative">
                                {productList &&
                                    productList.map((item) => (
                                        <div
                                            className="col-span-6 sm:col-span-6 lg:col-span-2"
                                            key={item.id}
                                        >
                                            <div className="relative flex items-center h-10">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        {...register(
                                                            `${item.id}`
                                                        )}
                                                        id="accept_term"
                                                        aria-describedby="accept_term"
                                                        name="accept_term"
                                                        type="checkbox"
                                                        className="focus:ring-mainDarkRed h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                        onChange={(e) => {
                                                            if (
                                                                e.target.checked
                                                            ) {
                                                                clothingProduct.push(
                                                                    {
                                                                        id: item.id,
                                                                    }
                                                                );
                                                                deleteclothingProduct =
                                                                    deleteclothingProduct.filter(
                                                                        (
                                                                            deleted
                                                                        ) =>
                                                                            deleted.id !==
                                                                            item.id
                                                                    );
                                                            } else {
                                                                deleteclothingProduct.push(
                                                                    {
                                                                        id: item.id,
                                                                    }
                                                                );
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <p className=" text-sm font-medium text-gray-700">
                                                        {item.name}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                {page !== lastPage && (
                                    <div className="w-full flex justify-center">
                                        <div
                                            className="cursor-pointer text-mainGray hover:text-mainDarkRed transition-all duration-500"
                                            onClick={() => setPage(page + 1)}
                                        >
                                            <BsPlusSquare size="1.6em" />
                                        </div>
                                    </div>
                                )}
                            </div>
                        </form>
                    </div>
                </div>

                <div className="mt-6 w-full flex justify-center md:justify-end gap-3">
                    <div
                        className="w-full md:w-32"
                        onClick={() => history.goBack()}
                    >
                        <SecondaryButton title="Voltar" />
                    </div>
                    <div className="w-full md:w-32">
                        <PrimaryButton
                            title="Salvar"
                            // form="userRegisterForm"
                            isLoading={isLoading}
                            onClick={() =>
                                handleRegisterProductToClothingSet(
                                    clothingProduct as [],
                                    deleteclothingProduct as []
                                )
                            }
                        />
                    </div>
                </div>
            </div>
            <div className="mt-2">
                {/*productList.length >= 10 && (
                    <Pagination
                        initPage={1}
                        totalPage={lastPage}
                        page={page}
                        setPage={setPage}
                        path={`products`}
                    />
                )*/}
            </div>
            <AlertModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                confirmButton={modal.customModal.confirmButton}
                path="/conjuntos-produtos"
            />
        </>
    );
}
