import { Link, useParams } from "react-router-dom";
import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import { useEffect, useState } from "react";

interface RegistrationProps {
    id: number;
    name: string;
    type_piece: string;
    type_tissue: string;
    category: string;
    created_at: string;
    updated_at: string;
}

const schema = yup.object().shape({
    name: yup.string().required("Nome é obrigatório"),
    type_piece: yup.string().required("Campo obrigatório"),
    type_tissue: yup.string().required("Campo obrigatório"),
    category: yup.string().required("Campo obrigatório"),
});

export default function ProductRegistration() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const modal = useCustomModal();

    let { id }: { id: string } = useParams();

    useEffect(() => {
        async function getProduct() {
            if (id) {
                const { data } = await api.get(`products/${id}`);

                setValue("name", data.name);
                setValue("type_piece", data.type_piece);
                setValue("type_tissue", data.type_tissue);
                setValue("category", data.category);
            }
        }

        getProduct();
    });

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<RegistrationProps>({
        resolver: yupResolver(schema),
    });

    async function handleRegisterProduct(data: RegistrationProps) {
        setIsLoading(true);
        const dataRegistration = {
            name: data.name,
            type_piece: data.type_piece,
            type_tissue: data.type_tissue,
            category: data.category,
        };
        try {
            let response;

            if (id) {
                response = await api.put(
                    `products/update/${id}`,
                    dataRegistration
                );
            } else {
                response = await api.post("products/add", dataRegistration);
            }

            if (response.data.status === "Error") {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível realizar seu cadastro",
                    text: response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
            } else {
                modal.setCustomModal({
                    status: true,
                    icon: "success",
                    title: "Produto cadastrado/editado com sucesso!",
                    text: "Agora você pode acessá-lo na área de produtos",
                    cancelButton: "",
                    confirmButton: "Ok",
                });
            }
            setIsLoading(false);
        } catch (e: any) {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Um erro inesperado aconteceu",
                text: e.response.data.message,
                cancelButton: "",
                confirmButton: "",
            });
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Cadastro de produtos
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Área de cadastro de produtos
                        </p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form
                            action="#"
                            method="POST"
                            id="productRegisterForm"
                            onSubmit={handleSubmit(handleRegisterProduct)}
                        >
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Nome do Produto
                                    </label>
                                    <input
                                        {...register("name")}
                                        type="text"
                                        name="name"
                                        id="name"
                                        autoComplete="given-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue("name", e.target.value);
                                        }}
                                    />
                                    {errors.name && (
                                        <span className="text-mainDarkRed">
                                            {errors.name.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="type_piece"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Classificação
                                    </label>
                                    <select
                                        {...register("type_piece")}
                                        id="type_piece"
                                        name="type_piece"
                                        autoComplete="type_piece-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue(
                                                "type_piece",
                                                e.target.value
                                            );
                                        }}
                                    >
                                        <option>Feminino</option>
                                        <option>Masculino</option>
                                        <option>Infantil</option>
                                    </select>
                                    {errors.type_piece && (
                                        <span className="text-mainDarkRed">
                                            {errors.type_piece.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="type_tissue"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Tipo do Tecido
                                    </label>
                                    <select
                                        {...register("type_tissue")}
                                        id="type_tissue"
                                        name="type_tissue"
                                        autoComplete="type_tissue-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue(
                                                "type_tissue",
                                                e.target.value
                                            );
                                        }}
                                    >
                                        <option>Malha</option>
                                        <option>Plano</option>
                                    </select>
                                    {errors.type_tissue && (
                                        <span className="text-mainDarkRed">
                                            {errors.type_tissue.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="category"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Categoria do Produto
                                    </label>
                                    <select
                                        {...register("category")}
                                        id="category"
                                        name="category"
                                        autoComplete="category-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue(
                                                "category",
                                                e.target.value
                                            );
                                        }}
                                    >
                                        <option>Top</option>
                                        <option>Botton</option>
                                        <option>Inteiro</option>
                                        <option>Acessório</option>
                                        <option>Esportivo</option>
                                        <option>Intima</option>
                                    </select>
                                    {errors.category && (
                                        <span className="text-mainDarkRed">
                                            {errors.category.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-6 w-full flex justify-center md:justify-end gap-3">
                    <div
                        className="w-full md:w-32"
                        onClick={() => history.goBack()}
                    >
                        <SecondaryButton title="Voltar" />
                    </div>
                    <div className="w-full md:w-32">
                        <PrimaryButton
                            title="Salvar"
                            form="productRegisterForm"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
            <AlertModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                confirmButton={modal.customModal.confirmButton}
                path="/produtos"
            />
        </>
    );
}
