import React, { useCallback, useContext, useEffect, useState } from "react";
import { Link, useLocation, useHistory, useParams } from "react-router-dom";

import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";
import InfoContext from "../../context/InfoContext";
import { Context } from "../../context/AuthContext";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import { CustomerProps } from "../../types/Customer";
import { MdAdd, MdClose } from "react-icons/md";
import { isTemplateExpression } from "typescript";

interface QuestionChecklist {
    id: number;
    groupName: string;
    groupDescription: string;
    question: string;
    product_id: number;
}

const schema = yup.object().shape({
    groupName: yup.string().required("Nome do grupo é obrigatório"),
    groupDescription: yup.string().required("Descrição de grupo é obrigatório"),
    // question: yup.string().required("Nome é obrigatório"),
});

function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
}

export default function ExtraQuestionsRegistration() {
    const { handleLogOut, user } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);
    const [isLoading, setIsLoading] = useState<boolean>(true);
    const [questionList, setQuestionList] = useState<any[]>([]);
    const [questionText, setQuestionText] = useState<string>("");
    const [customerSearch, setCustomerSearch] = useState<string>("");

    const [clientList, setClientList] = useState<any[]>([]);

    const [customerList, setCustomerList] = useState<any[]>();
    const [teste, setTeste] = useState<any[]>();

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [limit, setLimit] = useState(1);
    const [totalRegister, setTotalRegister] = useState(1);
    const [search, setSearch] = useState<string>("");

    const [isCheckAll, setIsCheckAll] = useState(false);
    const [isCheck, setIsCheck] = useState<any>([]);
    const [list, setList] = useState<any>([]);

    const modal = useCustomModal();
    const query = useQuery();

    const product_id = query.get("product_id");
    let { id }: { id: string } = useParams();

    const getCustomers = useCallback(async () => {
        try {
            const { data } = await api.get(
                `clients?query=${search}&page=${page}&limit=10`
            );

            setPage(data.currentPage);
            setLastPage(data.lastPage);
            setTotalRegister(data.totalRegister);
            setCustomerList(data.list);
            setIsLoading(false);
        } catch {
            handleLogOut();
        }
    }, [page, search]);

    useEffect(() => {
        getCustomers();
    }, [id, reloadData, page, search]);

    const adjustedClientList: any = [];

    useEffect(() => {
        async function getQuestion() {
            if (id) {
                const { data } = await api.get(`groups/${id}`);

                // setQuestion(data.question);
                setValue("groupName", data.name);
                setValue("groupDescription", data.description);
                setClientList(data.clients);
                setQuestionList(data.checklistQuestions);
            }
        }

        getQuestion();
    }, []);

    useEffect(() => {
        //trata os dados vindos do back
        clientList.forEach((element, index) =>
            adjustedClientList.push(clientList[index].id)
        );
        setIsCheck(adjustedClientList);
    }, [clientList]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<QuestionChecklist>({
        resolver: yupResolver(schema),
    });

    async function handleExtraQuestion(data: QuestionChecklist) {
        let response;

        const product_id = query.get("product_id") as any;
        data.product_id = product_id;

        const objQuestion = {
            // product_id: product_id,
            name: data.groupName,
            description: data.groupDescription,
            clients: isCheck,
            checklistQuestions: questionList.map((item: any, idx: number) => ({
                ...item,
                orderBy: idx,
            })),
        };
        console.log("send->", objQuestion);

        if (id) {
            response = await api.put(`groups/${id}`, objQuestion);
        } else {
            response = await api.post(`groups`, objQuestion);
        }

        if (response.data.status === "Error") {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Não foi possível realizar seu cadastro",
                text: response.data.message,
                cancelButton: "",
                confirmButton: "",
            });
        } else {
            modal.setCustomModal({
                status: true,
                icon: "success",
                title: "Grupo de perguntas cadastrado/editado com sucesso!",
                text: "Agora você pode acessá-lo na área de perguntas adicionais",
                cancelButton: "",
                confirmButton: "Ok",
            });
        }
    }

    const addToQuestionList = (question: any) => {
        if (
            question &&
            !questionList.find(({ question: i }) => i === question)
        ) {
            setQuestionList((state) => [...state, { question }]);
            setQuestionText("");
        }
    };

    const deleteQuestion = (index: number) => {
        setQuestionList((state) => state.filter((item, idx) => idx !== index));
    };

    // Add/Remove checked customers from list
    // const handleCheck = (event: any) => {
    //     var updatedList = [...clientList];
    //     if (event.target.checked) {
    //         updatedList = [...clientList, { id: event.target.value }];
    //     } else {
    //         // updatedList.splice(clientList.indexOf(event.target.value), 1);
    //         updatedList.filter((event) => id === event.target.value);

    //         console.log("removeuu");
    //     }
    //     setClientList(updatedList);
    //     setTeste(updatedList);
    // };

    useEffect(() => {
        setList(customerList);
    }, [list, adjustedClientList]);

    const handleClick = (e: any) => {
        const { id, checked } = e.target;
        setIsCheck([...isCheck, id]);
        if (!checked) {
            setIsCheck(isCheck.filter((item: any) => item !== id));
            // clientList.find((x) =>x.id === item.id
            // console.log("teste",isCheck.find((item: any) => item.id !== id));
            // setIsCheck(isCheck.find((item: any) => item !== id));
        }
    };

    const handleSelectAll = (e: any) => {
        setIsCheckAll(!isCheckAll);
        setIsCheck(list.map((li: any) => li.id));
        if (isCheckAll) {
            setIsCheck([]);
        }
    };

    return (
        <>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Cadastro de Perguntas
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Área de cadastro de Perguntas
                        </p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form
                            action="#"
                            method="POST"
                            id="productRegisterForm"
                            onSubmit={handleSubmit(handleExtraQuestion)}
                        >
                            <div className="flex flex-col w-1/2 gap-7">
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Nome do grupo
                                    </label>
                                    <input
                                        {...register("groupName")}
                                        type="text"
                                        name="groupName"
                                        id="groupName"
                                        autoComplete="given-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue(
                                                "groupName",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.groupName && (
                                        <span className="text-mainDarkRed">
                                            {errors.groupName.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="groupDescription"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Descrição de grupo
                                    </label>
                                    <textarea
                                        {...register("groupDescription")}
                                        // type="text"
                                        name="groupDescription"
                                        id="groupDescription"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.groupDescription && (
                                        <span className="text-mainDarkRed">
                                            {errors.groupDescription.message}
                                        </span>
                                    )}
                                </div>
                            </div>
                            <div>
                                <div className="block text-lg font-bold text-gray-700 mt-10">
                                    Lista de clientes
                                </div>
                                <div className="flex justify-between">
                                    <input
                                        className="mt-2 mb-4 block w-42 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        placeholder={"pesquisar clientes"}
                                        value={search}
                                        onChange={(e) =>
                                            setSearch(e.target.value)
                                        }
                                    />{" "}
                                    <div className="relative flex items-center h-10 mr-10">
                                        <div className="flex items-center h-5">
                                            <input
                                                // id={item.id}
                                                aria-describedby="clientSelect"
                                                name="clientSelect"
                                                type="checkbox"
                                                // value={item.id}
                                                // checked={
                                                //     clientList.find(
                                                //         (x) =>
                                                //             x.id ===
                                                //             item.id
                                                //     )
                                                //         ? true
                                                //         : false
                                                // }
                                                className="focus:ring-mainDarkRed h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                checked={isCheckAll}
                                                onChange={handleSelectAll}
                                            />
                                        </div>
                                        <div className="ml-3 ">
                                            {id ? (
                                                <p
                                                    className={`

                                                 text-md font-bold text-gray-700 `}
                                                >
                                                    Selecionar todos
                                                    {adjustedClientList}
                                                </p>
                                            ) : (
                                                <p
                                                    className={`

                                                 text-md font-bold text-gray-700 `}
                                                >
                                                    Selecionar todos
                                                    {clientList}
                                                </p>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="grid grid-cols-6 gap-6  h-[200px] overflow-y-auto">
                                    {customerList &&
                                        customerList.map((item, index) => (
                                            <div
                                                className="col-span-6 sm:col-span-6 lg:col-span-2"
                                                key={item.id}
                                            >
                                                <div className="relative flex items-center h-10">
                                                    <div
                                                        className="flex items-center h-5"
                                                        key={item.id}
                                                    >
                                                        <input
                                                            id={item.id}
                                                            aria-describedby="clientSelect"
                                                            name="clientSelect"
                                                            type="checkbox"
                                                            value={item.id}
                                                            // checked={
                                                            //     isCheck.find(
                                                            //         (x:any) =>
                                                            //             x.id ===
                                                            //             item.id
                                                            //     )
                                                            //         ? true
                                                            //         : false
                                                            // }
                                                            className="focus:ring-mainDarkRed h-4 w-4 text-indigo-600 border-gray-300 rounded"
                                                            checked={
                                                                isCheck.includes(
                                                                    item.id
                                                                )
                                                                    ? true
                                                                    : false
                                                            }
                                                            onChange={
                                                                handleClick
                                                            }
                                                        />
                                                    </div>
                                                    <div className="ml-3 text-sm">
                                                        <p
                                                            className={`text-sm font-medium text-gray-700 `}
                                                        >
                                                            {item.companyName}
                                                        </p>
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                </div>
                            </div>
                            <div>
                                <div className="flex flex-col gap-4">
                                    {/* inicio card */}
                                    <div className="border rounded-lg w-full mt-3 flex flex-col">
                                        <div className="flex flex-row mt-4 p-6">
                                            <div className="flex flex-col">
                                                <div> Perguntas</div>
                                                <input
                                                    className="mt-1 block w-42 border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                    placeholder={
                                                        "insira sua pergunta"
                                                    }
                                                    value={questionText}
                                                    onChange={(e) =>
                                                        setQuestionText(
                                                            e.target.value
                                                        )
                                                    }
                                                />{" "}
                                            </div>
                                            <div
                                                onClick={() => {
                                                    addToQuestionList(
                                                        questionText
                                                    );
                                                }}
                                                className="mt-[27px] ml-4 cursor-pointer bg-gray-300 h-10 w-10 rounded-lg flex justify-center items-center hover:bg-red-200 "
                                            >
                                                <MdAdd />
                                            </div>
                                        </div>

                                        <div className="max-h-48 overflow-y-auto">
                                            {questionList.length > 0 &&
                                                questionList.map(
                                                    (item, index) => (
                                                        <div
                                                            className="ml-4 flex justify-between w-fill h-10 items-center px-4 rounded-xl "
                                                            key={index}
                                                        >
                                                            <div className="flex ">
                                                                {item.question}
                                                                <div
                                                                    className="ml-4"
                                                                    onClick={() => {
                                                                        deleteQuestion(
                                                                            index
                                                                        );
                                                                    }}
                                                                >
                                                                    <MdClose
                                                                        size={
                                                                            20
                                                                        }
                                                                        className={
                                                                            "hover:text-red-500 cursor-pointer mt-1 "
                                                                        }
                                                                    />{" "}
                                                                </div>
                                                            </div>{" "}
                                                            {
                                                                <div className="mb-6 ml-5 cursor-pointer mr-[-17px] hover:brightness-125 opacity-50 hover:opacity-100">
                                                                    {/* <AiFillCloseCircle
                                                                        size={
                                                                            20
                                                                        }
                                                                        color={
                                                                            "#e80909"
                                                                        }
                                                                    /> */}
                                                                </div>
                                                            }
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </div>

                                    {/* final card */}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-6 w-full flex justify-center md:justify-end gap-3">
                    <div
                        className="w-full md:w-32"
                        onClick={() => history.goBack()}
                    >
                        <SecondaryButton title="Voltar" />
                    </div>
                    <div className="w-full md:w-32">
                        <PrimaryButton
                            title="Salvar"
                            form="productRegisterForm"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
            <AlertModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                confirmButton={modal.customModal.confirmButton}
                path={`/extraQuestions`}
            />
        </>
    );
}
