import moment from "moment";
import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { MdOutlinePictureAsPdf } from "react-icons/md";
import { RiFileExcel2Line } from "react-icons/ri";
import Toggle from "../../components/Toggle";
import api from "../../services/api";

export default function Reports() {
    const [searchDateStart, setSearchDateStart] = useState<any>("");
    const [searchDateEnd, setSearchDateEnd] = useState<any>("");
    const [confirmed, setConfirmed] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [unvaliable, setUnvaliable] = useState<boolean>(false);
    const token = localStorage.getItem("BV@token");

    useEffect(() => {
        setLoading(true);
        if (searchDateEnd !== "" && searchDateStart !== "") {
            try {
                const start = moment(searchDateEnd);
                const end = moment(searchDateStart);
                if (start >= end) {
                    setUnvaliable(false);
                    setLoading(false);
                } else {
                    setLoading(false);
                    setUnvaliable(true);
                }
            } catch {
                console.log("erro");
                setLoading(false);
                setUnvaliable(true);
            }
        } else {
            setUnvaliable(true);
            setLoading(false);
        }
    }, [searchDateEnd, searchDateStart]);

    async function downloadFile(type: string) {
        try {
            const response = await api.get(
                `reports/${confirmed ? "confirmed" : "canceled"
                }/${type}?days=${moment(searchDateStart).format(
                    "DD/MM/YYYY"
                )}-${moment(searchDateEnd).format("DD/MM/YYYY")}`,
                {
                    responseType: "blob",
                    headers: {
                        Authorization: `Bearer ${token?.replaceAll(`"`, "")}`,
                    },
                }
            );
            let url = window.URL.createObjectURL(response.data);
            let a = document.createElement("a");
            a.href = url;
            a.download = `relatorio.${type === "excel" ? "xlsx" : "pdf"}`;
            a.click();
        } catch (e) {
            console.log("erro", e);
        }
    }

    return (
        <div className="w-full h-full">
            <div className="text-2xl font-bold">Relatórios</div>
            <div className="col-span-6 mt-4 sm:col-span-3">
                <label
                    htmlFor="date_call"
                    className="block text-sm font-medium text-gray-700"
                >
                    Pesquise por data
                </label>
                <div className="flex items-center gap-3">
                    <input
                        type="date"
                        name="date_call"
                        id="date_call"
                        autoComplete="given-date"
                        onChange={(e) => setSearchDateStart(e.target.value)}
                        className="block px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm w-36 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                    />
                    <div className="">até</div>
                    <input
                        type="date"
                        name="date_call"
                        id="date_call"
                        autoComplete="given-date"
                        onChange={(e) => setSearchDateEnd(e.target.value)}
                        className="block px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm w-36 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                    />
                    <Toggle state={confirmed} setState={setConfirmed} />
                    <div
                        className={`${confirmed ? "text-mainDarkRed" : "text-mainGray"
                            } transition-all font-semibold`}
                    >
                        {confirmed ? "Confirmado" : "Cancelado"}
                    </div>
                </div>
                {searchDateEnd !== "" &&
                    searchDateStart !== "" &&
                    !loading &&
                    !unvaliable && (
                        <div className="flex flex-wrap items-center justify-start gap-4 mt-8">
                            <div
                                onClick={() =>
                                    toast.promise(
                                        downloadFile("excel"),
                                        {
                                            loading: 'Carregando...',
                                            success: 'Arquivo baixado com sucesso!',
                                            error: 'Erro ao baixar arquivo!',
                                        }
                                    )
                                }
                                className="w-48 flex gap-2 items-center transition-all duration-500 cursor-pointer justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  bg-[#4d4d4d] hover:bg-mainDarkRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mainDarkRed"
                            >
                                <div>Download</div>
                                <RiFileExcel2Line size="1.5em" />
                            </div>
                            <div
                                onClick={() =>
                                    toast.promise(
                                        downloadFile("pdf"),
                                        {
                                            loading: 'Carregando...',
                                            success: 'Arquivo baixado com sucesso!',
                                            error: 'Erro ao baixar arquivo!',
                                        }
                                    )
                                }
                                className="w-48 flex gap-2 items-center transition-all duration-500 cursor-pointer justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white  bg-[#4d4d4d] hover:bg-mainDarkRed focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-mainDarkRed"
                            >
                                <div>Download</div>
                                <MdOutlinePictureAsPdf size="1.5em" />
                            </div>
                        </div>
                    )}
            </div>
        </div>
    );
}
