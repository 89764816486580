import { MdAddCircle } from "react-icons/md";
import history from "../../services/history";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Context } from "../../context/AuthContext";
import api from "../../services/api";

import PrimaryButton from "../../components/Button/PrimaryButton";
import LoadingTable from "../../components/LoadingTable";
import InfoContext from "../../context/InfoContext";
import CustomerTable from "../../components/Table/CustomerTable";
import { CustomerProps } from "../../types/Customer";
import { Pagination } from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";

export default function Customers() {
    const [customerList, setCustomerList] = useState<CustomerProps[]>();

    const { handleLogOut, user } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);
    const [search, setSearch] = useState<string>("");

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [limit, setLimit] = useState(1);
    const [totalRegister, setTotalRegister] = useState(1);

    let { id }: { id: string } = useParams();
    const getCustomers = useCallback(async () => {
        try {
            const { data } = await api.get(
                `clients?query=${search}&page=${page}&limit=10`
            );

            setPage(data.currentPage);
            setLastPage(data.lastPage);
            setTotalRegister(data.totalRegister);
            setCustomerList(data.list);
            setIsLoading(true);
        } catch {
            handleLogOut();
        }
    }, [page, search]);

    useEffect(() => {
        getCustomers();
    }, [id, reloadData, page, search]);

    return (
        <div>
            <div className="flex justify-between items-center">
                <h1 className="font-bold text-2xl">Clientes</h1>
                <Link to="/clientes-registrar">
                    <PrimaryButton title="Cadastrar" />
                </Link>
            </div>
            <SearchBar search={search} setSearch={setSearch} />

            {isLoading ? (
                <>
                    {customerList ? (
                        <>
                            <CustomerTable list={customerList} />
                            <Pagination
                                totalPage={lastPage}
                                page={page}
                                setPage={setPage}
                                path={`factories`}
                            />
                        </>
                    ) : (
                        <main className="flex-grow flex flex-col justify-center max-w-7xl w-full mx-auto px-4 sm:px-6 lg:px-8">
                            <div className="py-16">
                                <div className="text-center">
                                    <p className="text-sm font-semibold text-indigo-600 uppercase tracking-wide"></p>
                                    <h1 className="mt-2 text-4xl font-extrabold text-gray-900 tracking-tight sm:text-5xl">
                                        No momento não temos fábricas
                                        cadastradas
                                    </h1>
                                    <p className="mt-2 text-base text-gray-500"></p>
                                </div>
                            </div>
                        </main>
                    )}
                </>
            ) : (
                <LoadingTable />
            )}
        </div>
    );
}
