import { useCallback, useEffect, useState } from "react";
import { useDropzone } from "react-dropzone";
import toast from "react-hot-toast";
import { CgCloseR } from "react-icons/cg";
import { HiOutlineUpload } from "react-icons/hi";
import { MdAddAPhoto, MdOutlineDownloadForOffline } from "react-icons/md";
import { VscFilePdf } from "react-icons/vsc";
import api from "../../services/api";

interface DropProps {
    original?: any[];
    newPic: any;
    setNewPic: Function;
    disabled?: boolean;
    sectionIndex?: number;
}

export default function MyDropzone({
    original = [],
    newPic = null,
    setNewPic,
    // setId,
    // id,
    disabled = false,
    sectionIndex,
}: DropProps) {
    /*const [uri, setUri] = useState(original);
    const [loading, setLoading] = useState(false);*/
    const [originalFiles, setOriginalFiles] = useState<any[]>([]);
    const [state, setState] = useState<any[]>(newPic);

    useEffect(() => {
        setOriginalFiles(original);
    }, [original]);

    async function downloadFile(type: string, pathurl: string) {
        try {
            const response = await api.get(pathurl, {
                responseType: "blob",
            });
            let url = window.URL.createObjectURL(response.data);
            let a = document.createElement("a");
            a.href = url;
            a.download = `${pathurl.split("-")[pathurl.split("-").length - 1]
                }.${type}`;
            a.click();
            console.log("response");
        } catch (e) {
            console.log("erro", e);
        }
    }

    const onDrop = useCallback(
        (acceptedFiles: any) => {
            // Do something with the files
            async function uploadFile() {
                // setLoading(true);
                if (sectionIndex !== undefined) {
                    console.log("fotos antes ->", newPic);
                    if (
                        acceptedFiles[0].type.includes("image") ||
                        acceptedFiles[0].type.includes("pdf")
                    ) {
                        setNewPic(
                            newPic.map((item: any, idx: number) =>
                                idx === sectionIndex
                                    ? [...item, ...acceptedFiles]
                                    : item
                            )
                        );
                        setState(
                            newPic.map((item: any, idx: number) =>
                                idx === sectionIndex
                                    ? [...item, ...acceptedFiles]
                                    : item
                            )
                        );
                    }
                } else {
                    if (
                        acceptedFiles[0].type.includes("image") ||
                        acceptedFiles[0].type.includes("pdf")
                    ) {
                        setNewPic([...newPic, ...acceptedFiles]);
                        setState([...newPic, ...acceptedFiles]);
                    }
                }
            }
            uploadFile();
        },
        [newPic, state]
    );

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop,
    });

    async function deleteCloudPic(id: string) {
        try {
            // eslint-disable-next-line no-restricted-globals
            const shure = confirm(
                "Esse arquivo ja esta salvo na nuvem se clicar em OK você vai deletar ela definitivamente?"
            );
            if (shure) {
                await api.delete(`factories/file/delete/${id}`);
                setOriginalFiles(
                    originalFiles.filter((item: any) => item.id !== id)
                );
            }
        } catch {
            console.log("error");
        }
    }

    async function deletePic(index: number) {
        if (sectionIndex !== undefined) {
            setNewPic(
                newPic.map((item: any, idx: any) =>
                    idx === sectionIndex
                        ? item.filter(
                            (subItem: any, indice: any) => indice !== index
                        )
                        : item
                )
            );
            setState(
                newPic.map((item: any, idx: any) =>
                    idx === sectionIndex
                        ? item.filter(
                            (subItem: any, indice: any) => indice !== index
                        )
                        : item
                )
            );
        } else {
            setNewPic(newPic.filter((item: any, idx: number) => index !== idx));
            setState(newPic.filter((item: any, idx: number) => index !== idx));
        }
    }

    return (
        <>
            <div className="w-32 h-24">
                <div className="absolute text-xs text-gray-300 -translate-y-4">
                    Recomendado até 10mb
                </div>
                {disabled ? (
                    <div
                        className={`h-24 w-32 ${disabled ? "opacity-75 cursor-not-allowed" : ""
                            } cursor-pointer bg-gray-200 line-center hover:opacity-50 transition-all duration-500`}
                    >
                        <MdAddAPhoto color="#c30036" size="1.5em" />
                    </div>
                ) : (
                    <div
                        className={`h-24 w-32 ${disabled ? "opacity-75 cursor-not-allowed" : ""
                            } cursor-pointer rounded bg-gray-100 border border-gray-200 flex justify-center items-center hover:border-mainDarkRed transition-all duration-500`}
                        {...getRootProps()}
                    >
                        <input {...getInputProps()} disabled={disabled} />
                        {isDragActive ? (
                            <HiOutlineUpload color="#2E2E2E" size="1.5em" />
                        ) : (
                            <HiOutlineUpload color="#c30036" size="1.5em" />
                        )}
                    </div>
                )}
            </div>
            {originalFiles.map((item: any) => (
                <div key={item.id} className="relative w-24 h-24">
                    <div className="absolute flex justify-end w-full border rounded border-mainDarkRed">
                        {item.extension === "pdf" ? (
                            <div
                                className={`h-24 w-24 text-mainDarkRed rounded border border-mainGray flex justify-center items-center`}
                            >
                                <VscFilePdf size={"2.5em"} />
                                <div className="absolute w-full h-8 pl-2 overflow-hidden text-xs translate-y-8 text-mainDarkRed">
                                    {
                                        item.filePath.split("-")[
                                        item.filePath.split("-").length - 1
                                        ]
                                    }
                                </div>
                            </div>
                        ) : (
                            <img
                                className="w-24 h-24 rounded"
                                src={item.fileUrl}
                            />
                        )}
                        <div className="absolute flex items-center justify-between w-full">
                            <div
                                onClick={() =>
                                    toast.promise(
                                        downloadFile(item.extension, item.fileUrl),
                                        {
                                            loading: 'Carregando...',
                                            success: 'Arquivo baixado com sucesso!',
                                            error: 'Erro ao baixar arquivo!',
                                        }
                                    )
                                }
                                className="bg-white p-[2px] m-1 rounded opacity-100 cursor-pointer hover:opacity-80 transition-all duration-500"
                            >
                                <MdOutlineDownloadForOffline
                                    color={"#c30036"}
                                />
                            </div>
                            <div
                                onClick={() => deleteCloudPic(item.id)}
                                className="bg-white p-[2px] m-1 rounded opacity-100 cursor-pointer hover:opacity-80 transition-all duration-500"
                            >
                                <CgCloseR color={"#c30036"} />
                            </div>
                        </div>
                    </div>
                </div>
            ))}
            {newPic !== null && sectionIndex === undefined
                ? state.map((item: any, idx: number) => (
                    <div>
                        <div
                            key={URL.createObjectURL(item)}
                            className="relative w-24 h-24"
                        >
                            <div className="absolute flex justify-end w-full">
                                <div
                                    onClick={() => deletePic(idx)}
                                    className="bg-white p-[2px] m-1 rounded opacity-100 cursor-pointer hover:opacity-80 transition-all duration-500"
                                >
                                    <CgCloseR color={"#c30036"} />
                                </div>
                            </div>
                            {item.type.includes("image") ? (
                                <img
                                    className="w-24 h-24 rounded"
                                    src={URL.createObjectURL(item)}
                                />
                            ) : (
                                item.type.includes("pdf") && (
                                    <div
                                        className={`h-24 w-24 text-mainDarkRed rounded border border-mainGray flex justify-center items-center`}
                                    >
                                        <VscFilePdf size={"2.5em"} />
                                        <div className="absolute w-full h-8 pl-2 overflow-hidden text-xs translate-y-8 text-mainDarkRed">
                                            {item.name}
                                        </div>
                                    </div>
                                )
                            )}
                        </div>
                    </div>
                ))
                : state.length !== undefined &&
                // @ts-ignore
                state[sectionIndex].map((item: any, idx: number) => (
                    <div
                        key={URL.createObjectURL(item)}
                        className="relative w-24 h-24"
                    >
                        <div className="absolute flex justify-end w-full">
                            <div
                                onClick={() => deletePic(idx)}
                                className="bg-white p-[2px] m-1 rounded opacity-100 cursor-pointer hover:opacity-80 transition-all duration-500"
                            >
                                <CgCloseR color={"#c30036"} />
                            </div>
                        </div>
                        {item.type.includes("image") ? (
                            <img
                                className="w-24 h-24 rounded"
                                src={URL.createObjectURL(item)}
                            />
                        ) : (
                            item.type.includes("pdf") && (
                                <div
                                    className={`h-24 w-24 text-mainDarkRed rounded border border-mainGray flex justify-center items-center`}
                                >
                                    <VscFilePdf size={"2.5em"} />
                                    <div className="absolute w-full h-8 pl-2 overflow-hidden text-xs translate-y-8 text-mainDarkRed">
                                        {item.name}
                                    </div>
                                </div>
                            )
                        )}
                    </div>
                ))}
        </>
    );
}
