import { Link, useParams } from "react-router-dom";

import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import { useEffect, useState } from "react";
import { handleChangeMask } from "../../services/utils/mask";

interface RegistrationProps {
    name_defect: string;
    type_defect: string;
    specific_defect: string;
}

const schema = yup.object().shape({
    name_defect: yup.string().required("Campo obrigatório"),
    type_defect: yup.string().required("Campo obrigatório"),
    specific_defect: yup.string().required("Campo obrigatório"),
});

export default function DefectsRegistration() {
    const [isLoading, setIsLoading] = useState<boolean>(false);

    let { id }: { id: string } = useParams();

    const modal = useCustomModal();

    useEffect(() => {
        async function getDefect() {
            if (id) {
                const { data } = await api.get(`defects/${id}`);

                setValue("name_defect", data.name_defect);
                setValue("type_defect", data.type_defect);
                setValue("specific_defect", data.specific_defect);
            }
        }

        getDefect();
    }, []);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<RegistrationProps>({
        resolver: yupResolver(schema),
    });

    async function handleRegister(data: RegistrationProps) {
        setIsLoading(true);
        const dataRegistration = {
            name_defect: data.name_defect,
            type_defect: data.type_defect,
            specific_defect: data.specific_defect,
        };

        try {
            let response;
            if (id) {
                response = await api.put(
                    `defects/update/${id}`,
                    dataRegistration
                );
            } else {
                response = await api.post("defects/add", dataRegistration);
            }

            if (response.data.status === "Error") {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível realizar seu cadastro",
                    text: response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
            } else {
                modal.setCustomModal({
                    status: true,
                    icon: "success",
                    title: "Defeito cadastrado/editado com sucesso!",
                    text: "Agora você pode acessá-lo na área de defeitos",
                    cancelButton: "",
                    confirmButton: "Ok",
                });
            }
            setIsLoading(false);
        } catch (e: any) {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Um erro inesperado aconteceu",
                text: e.response.data.message,
                cancelButton: "",
                confirmButton: "",
            });
            setIsLoading(false);
        }
    }

    return (
        <>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Cadastro de defeitos
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Área de cadastro de defeitos
                        </p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form
                            action="#"
                            method="POST"
                            id="registerForm"
                            onSubmit={handleSubmit(handleRegister)}
                        >
                            <div className=" sm:overflow-hidden">
                                <div className="bg-white py-6  space-y-6 sm:p-6">
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6">
                                            <label
                                                htmlFor="name_defect"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Nome do defeito
                                            </label>
                                            <input
                                                {...register("name_defect")}
                                                type="text"
                                                name="name_defect"
                                                id="name_defect"
                                                autoComplete="name_defect"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "name_defect",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.name_defect && (
                                                <span className="text-mainDarkRed">
                                                    {errors.name_defect.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="type_defect"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Tipo do defeito
                                            </label>
                                            <select
                                                {...register("type_defect")}
                                                id="type_defect"
                                                name="type_defect"
                                                autoComplete="type_defect-name"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "type_defect",
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Visual</option>
                                                <option>Logistico</option>
                                            </select>
                                            {errors.type_defect && (
                                                <span className="text-mainDarkRed">
                                                    {errors.type_defect.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="specific_defect"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Categoria do defeito
                                            </label>
                                            <select
                                                {...register("specific_defect")}
                                                id="specific_defect"
                                                name="specific_defect"
                                                autoComplete="specific_defect-name"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "specific_defect",
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option>Matéria-prima</option>
                                                <option>
                                                    Confecção ou Montagem
                                                </option>
                                                <option>Aviamentos</option>
                                                <option>
                                                    Medidas ou Tamanho
                                                </option>
                                                <option>Etiquetas</option>
                                                <option>
                                                    Não conformidade
                                                </option>
                                                <option>
                                                    Forma de Entrega/ Embalagens
                                                </option>
                                            </select>
                                            {errors.specific_defect && (
                                                <span className="text-mainDarkRed">
                                                    {
                                                        errors.specific_defect
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div>
                                        {/* 
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="specific_defect"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Espeficação do defeito
                                            </label>
                                            <input
                                                {...register("specific_defect")}
                                                type="text"
                                                name="specific_defect"
                                                id="specific_defect"
                                                autoComplete="specific_defect"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                            />
                                            {errors.specific_defect && (
                                                <span className="text-mainDarkRed">
                                                    {
                                                        errors.specific_defect
                                                            .message
                                                    }
                                                </span>
                                            )}
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="mt-6 w-full flex justify-center md:justify-end gap-3">
                    <div
                        className="w-full md:w-32"
                        onClick={() => history.goBack()}
                    >
                        <SecondaryButton title="Voltar" />
                    </div>
                    <div className="w-full md:w-32">
                        <PrimaryButton
                            title="Salvar"
                            form="registerForm"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
            <AlertModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                confirmButton={modal.customModal.confirmButton}
                path="/defeitos"
            />
        </>
    );
}
