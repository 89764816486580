import React, { useState } from "react";

interface ToggleProps {
    state: boolean;
    setState: Function;
}

export default function Toggle({ state, setState }: ToggleProps) {
    return (
        <div
            className={`w-8 h-4 border rounded-full flex justify-start items-center
                transition-all ${
                    state ? "border-mainDarkRed" : "border-mainGray"
                }
            `}
            onClick={() => setState(!state)}
        >
            <div
                className={`w-4 h-4 rounded-full
                 ${
                     state ? "translate-x-4 bg-mainDarkRed" : "bg-mainGray"
                 } transition-all
            `}
            />
        </div>
    );
}
