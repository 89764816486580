import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import {
    useForm,
    useFieldArray,
    Controller,
    useWatch,
    Control,
} from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import { useContext, useEffect, useState } from "react";
import { ProductsProps } from "../../types/Products";
import { Context } from "../../context/AuthContext";
import { useParams } from "react-router-dom";

import { MdAdd, MdClose } from "react-icons/md";
import {
    handleChangeMask,
    maskNumber,
    maskNumberComma,
    decimalNumber,
} from "../../services/utils/mask";

type FormValues = {
    name: string;
    product_id: number;
    evaluationSizes: {
        name: string;
        size: string;
        min: number | string;
        max: number | string;
    }[];
};

const Total = ({ control }: { control: Control<FormValues> }) => {
    const formValues = useWatch({
        name: "evaluationSizes",
        control,
    });
    // const total = formValues.reduce(
    //     (acc, current) => acc + (current.min || 0) * (current.max || 0),
    //     0
    // );
    // return <p>Total Amount: {total}</p>;
};

// const schema = yup.object().shape({
//     name_evaluation: yup.string().required("Campo obrigatório"),
//     size: yup.string().required("Campo obrigatório"),
//     tolerance: yup.string().required("Campo obrigatório"),
// });

export default function ParamsRegistration() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [product, setProduct] = useState<ProductsProps>();
    const [tolerance, setTolerance] = useState<string>();

    const modal = useCustomModal();
    const { handleLogOut, user } = useContext(Context);

    let { idProduct, id }: { idProduct: string; id: string } = useParams();

    useEffect(() => {
        async function getProduct() {
            try {
                const { data } = await api.get(`products/${idProduct}`);
                setProduct(data);
            } catch {
                handleLogOut();
            }
        }

        getProduct();
    }, []);

    useEffect(() => {
        async function getParameter() {
            if (id) {
                const { data } = await api.get(`parameters/${id}`);

                setValue("name", data.name);

                remove();
                if (data.evaluationSizes.length > 0) {
                    for (const keyEvolution in data.evaluationSizes) {
                        append({
                            name: data.evaluationSizes[keyEvolution].name,
                            size: data.evaluationSizes[keyEvolution].size,
                            min: data.evaluationSizes[keyEvolution].min,
                        });
                    }
                }
            }
        }

        getParameter();
    }, [id]);

    const {
        register,
        handleSubmit,
        control,
        setValue,
        formState: { errors },
    } = useForm<FormValues>({
        // resolver: yupResolver(schema),
        defaultValues: {
            product_id: product && product.id,
            name: "",
            evaluationSizes: [
                {
                    name: "",
                    size: "",
                    min: 0,
                    max: 0,
                },
            ],
        },
        mode: "onBlur",
    });

    const { fields, append, remove } = useFieldArray({
        name: "evaluationSizes",
        control,
    });

    const onSubmit = async (data: FormValues) => {
        setIsLoading(true);
        const outputData: any = {
            ...data,
            evaluationSizes: data.evaluationSizes.map(
                (item: any, idx: number) => ({ ...item, orderBy: idx })
            ),
        };
        console.log(data);

        try {
            let response;

            if (id) {
                response = await api.put(`parameters/update/${id}`, outputData);
            } else {
                response = await api.post("parameters/add", outputData);
            }

            if (response.data.status === "Error") {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível realizar seu cadastro",
                    text: response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
            } else {
                modal.setCustomModal({
                    status: true,
                    icon: "success",
                    title: "Parâmetro cadastrado/editado com sucesso!",
                    text: "Agora você pode acessá-lo na área de parâmetros",
                    cancelButton: "",
                    confirmButton: "Ok",
                });
            }
            setIsLoading(false);
        } catch (e: any) {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Um erro inesperado aconteceu",
                text: e.response.data.message,
                cancelButton: "",
                confirmButton: "",
            });
            setIsLoading(false);
        }
    };

    useEffect(() => {
        product && setValue("product_id", product.id);
    }, [product]);

    return (
        <>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Cadastro de parâmetros de produtos
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Área de cadastro de parâmetros de produtos
                        </p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form
                            action="#"
                            method="POST"
                            id="productRegisterForm"
                            onSubmit={handleSubmit(onSubmit)}
                        >
                            <div className="grid grid-cols-6 gap-6 p-6">
                                <div className="col-span-6">
                                    <label
                                        htmlFor="name"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Ponto de medida
                                    </label>
                                    <input
                                        {...register(`name` as const, {
                                            required: true,
                                        })}
                                        type="text"
                                        name="name_evaluation"
                                        id="name_evaluation"
                                        autoComplete="given-name-evaluation"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) =>
                                            setValue(`name`, e.target.value)
                                        }
                                    />
                                    <span className="text-mainDarkRed">
                                        {errors?.name
                                            ? "Campo obrigatório"
                                            : ""}
                                    </span>
                                </div>
                            </div>
                            {
                                // *  grid box avaliações * //
                            }
                            {fields.map((field, index) => (
                                <>
                                    <div className="flex flex-col gap-4">
                                        {/* inicio card */}
                                        <div className="border rounded-lg w-full my-3">
                                            <div className="w-full flex gap-3 justify-end pt-3 px-3">
                                                <div className="w-12">
                                                    <SecondaryButton
                                                        onClick={() =>
                                                            remove(index)
                                                        }
                                                    >
                                                        <MdClose size={18} />
                                                    </SecondaryButton>
                                                </div>
                                            </div>
                                            <div className="grid grid-cols-6 gap-6 p-6">
                                                <div className="col-span-6 sm:col-span-2">
                                                    <label
                                                        htmlFor="name_evaluation"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Tamanho
                                                    </label>
                                                    <input
                                                        {...register(
                                                            `evaluationSizes.${index}.name` as const,
                                                            {
                                                                required: true,
                                                            }
                                                        )}
                                                        type="text"
                                                        name="name_evaluation"
                                                        id="name_evaluation"
                                                        autoComplete="given-name-evaluation"
                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                        onChange={(e) =>
                                                            setValue(
                                                                `evaluationSizes.${index}.name`,
                                                                e.target.value
                                                            )
                                                        }
                                                    />

                                                    <span className="text-mainDarkRed">
                                                        {errors
                                                            ?.evaluationSizes?.[
                                                            index
                                                        ]?.name
                                                            ? "Campo obrigatório"
                                                            : ""}
                                                    </span>
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label
                                                        htmlFor="size"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Valor padrão
                                                    </label>
                                                    <input
                                                        {...register(
                                                            `evaluationSizes.${index}.size` as const,
                                                            {
                                                                required: true,
                                                            }
                                                        )}
                                                        type="text"
                                                        name="size"
                                                        id="size"
                                                        autoComplete="given-name-evaluation"
                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                        onChange={(e) =>
                                                            setValue(
                                                                `evaluationSizes.${index}.size`,
                                                                decimalNumber(
                                                                    e.target
                                                                        .value
                                                                )
                                                            )
                                                        }
                                                    />
                                                    <span className="text-mainDarkRed">
                                                        {errors
                                                            ?.evaluationSizes?.[
                                                            index
                                                        ]?.size
                                                            ? "Campo obrigatório"
                                                            : ""}
                                                    </span>
                                                </div>

                                                <div className="col-span-6 sm:col-span-2">
                                                    <label
                                                        htmlFor="tolerance"
                                                        className="block text-sm font-medium text-gray-700"
                                                    >
                                                        Tolerância
                                                    </label>
                                                    <input
                                                        {...register(
                                                            `evaluationSizes.${index}.min` as const,
                                                            {
                                                                valueAsNumber:
                                                                    true,
                                                                required: true,
                                                            }
                                                        )}
                                                        type="text"
                                                        name="tolerance"
                                                        id="tolerance"
                                                        autoComplete="given-name-evaluation"
                                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                        onChange={(e) => {
                                                            setValue(
                                                                `evaluationSizes.${index}.min`,
                                                                // parseFloat(
                                                                e.target.value
                                                                    .replace(
                                                                        /\D/g,
                                                                        ""
                                                                    )
                                                                    .replace(
                                                                        /\D+/g,
                                                                        ""
                                                                    )
                                                                    .replace(
                                                                        /(\d)(\d{2})/,
                                                                        "$1.$2"
                                                                    )
                                                            );
                                                            // );
                                                            setValue(
                                                                `evaluationSizes.${index}.max`,

                                                                e.target.value
                                                                    .replace(
                                                                        /\D+/g,
                                                                        ""
                                                                    )
                                                                    .replace(
                                                                        /(\d)(\d{2})/,
                                                                        "$1.$2"
                                                                    )
                                                            );
                                                        }}
                                                    />
                                                    <span className="text-mainDarkRed">
                                                        {errors
                                                            ?.evaluationSizes?.[
                                                            index
                                                        ]?.min
                                                            ? "Campo obrigatório"
                                                            : ""}
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                        {/* final card */}
                                    </div>
                                </>
                            ))}
                            <div className="mt-3">
                                <PrimaryButton
                                    onClick={() =>
                                        append({
                                            name: "",
                                            size: "",
                                            min: 0,
                                        })
                                    }
                                >
                                    <MdAdd size={18} />
                                </PrimaryButton>
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-6 w-full flex justify-center md:justify-end gap-3">
                    <div
                        className="w-full md:w-32"
                        onClick={() => history.goBack()}
                    >
                        <SecondaryButton title="Voltar" />
                    </div>
                    <div className="w-full md:w-32">
                        <PrimaryButton
                            title="Salvar"
                            form="productRegisterForm"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
            <AlertModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                confirmButton={modal.customModal.confirmButton}
                path={`/produtos-parametros/${idProduct}`}
            />
        </>
    );
}
