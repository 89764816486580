import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";
import { Context } from "../../context/AuthContext";
import { addressByCep } from "../../services/utils/getAddressByCep";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import { useContext, useEffect, useState, useRef } from "react";
import { handleChangeMask, maskNumber } from "../../services/utils/mask";
import { StringLiteralType } from "typescript";
import moment from "moment";

interface SolicitationProps {
    date_call: string;
    period: string;
    article: string;
    has_table_measurement: boolean;
    table_code: string;
    article_classification: string;
    category: string;
    material: string;
    quantity: number;
    level_of_pricing: string;
    responsible: string;
    factory_id: string;
    cargo: string;
    product: string;
    clothingSet: string;
    level_avaliation: string;
    auditor: string;
    tipo_produto: string;
    numero_pedido: string;
    productDescription: string;
    fabrica: string;
    packsAmount: number;
    quantityInspectedParts: number;
    kindOfPacking: string;
    bonusOrder: string;
    clientId: string;
    zip_code: string;
    address: string;
    number: string;
    district: string;
    description: string;
    city: string;
    state: string;
    productId: [];
}

interface ProductAndClothingSelect {
    id: number;
    name: string;
}

interface AuditorSelect {
    id: number;
    fullname: string;
}

const schema = yup.object().shape({
    date_call: yup.string().required("Campo é obrigatório"),
    period: yup.string().required("Campo é obrigatório"),
    article: yup.string().required("Campo é obrigatório"),
    has_table_measurement: yup.string(),
    tipo_produto: yup.string(),
    article_classification: yup.string().required("Campo é obrigatório"),
    category: yup.string().required("Campo é obrigatório"),
    material: yup.string().required("Campo é obrigatório"),
    responsible: yup.string().required("Campo é obrigatório"),
    cargo: yup.string().required("Campo é obrigatório"),
    kindOfPacking: yup.string(),
    bonusOrder: yup.string(),
    productDescription: yup.string(),
    clientId: yup.string(),
    auditor: yup.string().required("O Auditor é obrigatório").nullable(),
    packsAmount: yup.string().required("Campo obrigatório"),
    product: yup
        .string()
        .required("Por favor escolha um produto")
        .typeError("Por favor escolha um produto"),

    number: yup
        .string()
        .required("Campo obrigatório")
        .max(10, "Máximo 10 caracteres"),
    address: yup.string().required("Campo obrigatório"),
    district: yup
        .string()
        .required("Campo obrigatório")
        .max(150, "Máximo 150 caracteres"),
    description: yup.string().max(150, "Máximo 150 caracteres"),
    city: yup
        .string()
        .required("Campo obrigatório")
        .max(150, "Máximo 150 caracteres"),
    state: yup
        .string()
        .required("Campo obrigatório")
        .max(2, "Máximo 2 caracteres"),
    zip_code: yup
        .string()
        .required("Campo obrigatório")
        .min(9, "Mínimo 9 caracteres"),
    quantity: yup.string().required("Campo obrigatorio").nullable(),
});

const kitSchema = yup.object().shape({
    date_call: yup.string().required("Campo é obrigatório"),
    period: yup.string().required("Campo é obrigatório"),
    article: yup.string().required("Campo é obrigatório"),
    has_table_measurement: yup.string(),
    tipo_produto: yup.string(),
    article_classification: yup.string().required("Campo é obrigatório"),
    category: yup.string().required("Campo é obrigatório"),
    material: yup.string().required("Campo é obrigatório"),
    responsible: yup.string().required("Campo é obrigatório"),
    cargo: yup.string().required("Campo é obrigatório"),
    kindOfPacking: yup.string(),
    bonusOrder: yup.string(),
    productDescription: yup.string(),
    clientId: yup.string(),
    auditor: yup.string().required("O Auditor é obrigatório").nullable(),
    packsAmount: yup.string().required("Campo obrigatório"),
    clothingSet: yup
        .string()
        .required("Por favor escolha um kit")
        .typeError("Por favor escolha um kit"),
    number: yup
        .string()
        .required("Campo obrigatório")
        .max(10, "Máximo 10 caracteres"),
    address: yup.string().required("Campo obrigatório"),
    district: yup
        .string()
        .required("Campo obrigatório")
        .max(150, "Máximo 150 caracteres"),
    description: yup.string().max(150, "Máximo 150 caracteres"),
    city: yup
        .string()
        .required("Campo obrigatório")
        .max(150, "Máximo 150 caracteres"),
    state: yup
        .string()
        .required("Campo obrigatório")
        .max(2, "Máximo 2 caracteres"),
    zip_code: yup
        .string()
        .required("Campo obrigatório")
        .min(9, "Mínimo 9 caracteres"),
    quantity: yup.string().required("Campo obrigatorio").nullable(),
});

export default function SolicitationForm() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [period, setPeriod] = useState<string>("Manhã");
    const [table, setTable] = useState<string>("Não");
    const [factories, setFactories] = useState<any[]>([]);
    const [articleClassification, setArticleClassification] =
        useState<string>("Não");

    const { handleLogOut, user } = useContext(Context);

    const [possuiMedidas, setPossuiMedidas] = useState<boolean>(false);
    const [isClothingSet, setIsClothingSet] = useState<boolean>(false);
    const [isClientSelected, setIsClientSelected] = useState<string>("");

    const [listSelectProduct, setListSelectProduct] =
        useState<ProductAndClothingSelect[]>();
    const [listSelectClothingSet, setListSelectClothingSet] =
        useState<ProductAndClothingSelect[]>();
    const [listAuditor, setListAuditor] = useState<AuditorSelect[]>();

    const [searchClients, setSearchClients] = useState("");
    const [clientId, setClientId] = useState("");
    const [listClients, setListClients] = useState<any[]>();
    const [suggestionActive, setSuggestionActive] = useState<boolean>(false);

    const [orderNumber, setOrderNumber] = useState("");
    const [quantity, setQuantity] = useState("");
    const [packQuantity, setPackQuantity] = useState("");
    const [piecesQuantity, setPiecesQuantity] = useState("");
    const [tableCode, setTableCode] = useState<string>("");
    const [level, setLevel] = useState<string>("intermediario_atenuado");
    const [pricing, setPricing] = useState<string>("P1");
    const [cep, setCep] = useState<string>("");
    const [selectedFactory, setSelectedFactory] = useState<any>("");
    const [outputFactory, setOutputFactory] = useState<string>("");

    const [registrationCode, setRegistrationCode] = useState<string>("");
    const [supplierCode, setSupplierCode] = useState<string>("");

    const modal = useCustomModal();

    useEffect(() => {
        async function getFactoryAddress() {
            try {
                const response = await api.get(`factories/${selectedFactory}`);
                setValue("zip_code", response.data.address.zip_code);
                setValue("address", response.data.address.address);
                setValue("number", response.data.address.number);
                setValue("district", response.data.address.district);
                setValue("description", response.data.address.description);
                setValue("city", response.data.address.city);
                setValue("state", response.data.address.state);
                setSupplierCode(response.data.supplierCode);
                setRegistrationCode(response.data.factoryCode);
                setOutputFactory(selectedFactory);
            } catch {
                console.log("error");
            }
        }
        getFactoryAddress();
    }, [selectedFactory]);

    useEffect(() => {
        async function getProductLists() {
            const { data } = await api.get(`products/list/select`);
            console.log("lista->", data);
            setListSelectProduct(data);
        }

        async function getClothingSetLists() {
            const { data } = await api.get(`clothingsets/list/select`);
            setListSelectClothingSet(data);
        }

        async function getAuditorList() {
            const auditorSelect = await api.get(`auditors/list/select`);
            setListAuditor(auditorSelect.data);
        }

        async function getCustomersLists() {
            const { data } = await api.get(
                `clients?query=${searchClients}&limit=100`
            );
            setListClients(data.list);
            console.log("Customers", data.list);
            console.log("Customers", searchClients);
        }

        async function getFactoryList() {
            const { data } = await api.get(`factories`);
            setFactories(data.list);
            console.log("fabricas ->", data.list);
        }

        getClothingSetLists();
        getProductLists();
        getFactoryList();
        getAuditorList();
        getCustomersLists();
    }, [searchClients]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<SolicitationProps>({
        resolver: yupResolver(isClothingSet ? kitSchema : schema),
    });

    async function handleRegisterClient(data: SolicitationProps) {
        setIsLoading(true);

        if (
            (data.tipo_produto === "conjunto" && data.clothingSet !== "") ||
            (data.tipo_produto !== "conjunto" && data.product !== "")
        ) {
            console.log(data.product);
            const dataRegistration = {
                date_call: data.date_call,
                period: data.period,
                article: data.article,
                has_table_measurement: possuiMedidas,
                table_code: tableCode,
                article_classification: data.article_classification,
                category: data.category,
                material: data.material,
                quantity: quantity,
                level_of_pricing: pricing,
                level_avaliation: level,
                responsible: data.responsible,
                factory_id: outputFactory,
                cargo: data.cargo,
                numberSolicitation: orderNumber,
                // !
                clientId: listClients?.filter(
                    (item: any) => item.companyName === searchClients
                )[0].id,
                auditor_id: data.auditor,
                // factory: { supplierCode: data.supplierCode },
                packsAmount: packQuantity,
                quantityInspectedParts: piecesQuantity,
                kindOfPacking: data.kindOfPacking,
                bonusOrder: data.bonusOrder,
                // clientId: clientId,
                productDescription: data.productDescription,
                collective: isClothingSet,

                address: {
                    zip_code: data.zip_code,
                    address: data.address,
                    number: data.number,
                    district: data.district,
                    description: data.description,
                    city: data.city,
                    state: data.state,
                },
                productId: !isClothingSet ? Number(data.product) : undefined,
                clothing_set_id: isClothingSet ? data.clothingSet : undefined,
            };

            try {
                const response = await api.post(
                    "solicitations/add",
                    dataRegistration
                );
                if (response.data.status === "Error") {
                    modal.setCustomModal({
                        status: true,
                        icon: "error",
                        title: "Não foi possível realizar seu cadastro",
                        text: response.data.message,
                        cancelButton: "",
                        confirmButton: "",
                    });
                } else {
                    modal.setCustomModal({
                        status: true,
                        icon: "success",
                        title: "Solicitação cadastrada com sucesso!",
                        text: "Agora você pode acessá-lo na área de solicitações",
                        cancelButton: "",
                        confirmButton: "Ok",
                    });
                }
                setIsLoading(false);
            } catch (e: any) {
                console.log("erro->", e.response.data.message);
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Um erro inesperado aconteceu",
                    text: e.response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
                setIsLoading(false);
            }
        } else {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "ERRO",
                text: `selecione ${
                    data.tipo_produto === "conjunto"
                        ? "um conjunto para enviar o formulario."
                        : "um produto para enviar o formulario."
                }`,
                cancelButton: "",
                confirmButton: "",
            });
            setIsLoading(false);
        }
    }

    useEffect(() => {
        async function getQuantityOfInspectedPieces() {
            const { data } = await api.get(
                `solicitations/quantity/inspectable/parts?level=${level}&pricing=${pricing}&quantity=${quantity}`
            );
            console.log("infoo", data.quantityInspectedParts);
            setPiecesQuantity(data.quantityInspectedParts);
        }

        getQuantityOfInspectedPieces();
    }, [quantity, pricing, level]);

    async function completeAddress(cep: string) {
        if (cep.length === 9) {
            let cepSearch = cep.replace("-", "");
            const address = await addressByCep(cepSearch);

            // setValue('zip_code', address.cep);
            if (address.logradouro) {
                setValue("address", address.logradouro);
                setValue("number", address.number);
                setValue("district", address.bairro);
                setValue("description", address.complemento);
                setValue("city", address.localidade);
                setValue("state", address.uf);
            }
        }
    }

    const useOutsideClick = (callback: any) => {
        const ref = useRef<any>();

        useEffect(() => {
            const handleClick = (event: any) => {
                if (ref.current && !ref.current.contains(event.target)) {
                    callback();
                }
            };

            document.addEventListener("click", handleClick, true);

            return () => {
                document.removeEventListener("click", handleClick, true);
            };
        }, [ref]);

        return ref;
    };

    const handleClickOutside = () => {
        setSuggestionActive(false);
    };
    const ref = useOutsideClick(handleClickOutside);

    function selectDefaultDay(today: Date) {
        if (today.getDay() < 6) {
            if (today.getDay() < 4) {
                return moment(today).add(2, "d").format("YYYY-MM-DD");
            } else {
                return moment(today).add(4, "d").format("YYYY-MM-DD");
            }
        } else {
            return moment(today).add(3, "d").format("YYYY-MM-DD");
        }
    }

    return (
        <>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Criar solicitação
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Área para cadastro de solicitação
                        </p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form
                            action="#"
                            method="POST"
                            id="userRegisterForm"
                            onSubmit={handleSubmit(handleRegisterClient)}
                        >
                            <div className="grid grid-cols-6 gap-6">
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="numero_pedido"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Nº do Pedido
                                    </label>
                                    <input
                                        // {...register("numero_pedido")}
                                        // type="number"
                                        name="numero_pedido"
                                        id="numero_pedido"
                                        min="0"
                                        value={orderNumber}
                                        onChange={(e) =>
                                            setOrderNumber(
                                                maskNumber(e.target.value)
                                            )
                                        }
                                        autoComplete="numero_pedido"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.numero_pedido && (
                                        <span className="text-mainDarkRed">
                                            {errors.numero_pedido.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="date_call"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Data de agendamento
                                    </label>
                                    <input
                                        {...register("date_call")}
                                        type="date"
                                        name="date_call"
                                        id="date_call"
                                        min={moment(new Date()).format(
                                            "YYYY-MM-DD"
                                        )}
                                        autoComplete="given-date"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.date_call && (
                                        <span className="text-mainDarkRed">
                                            {errors.date_call.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="period"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Período
                                    </label>
                                    <select
                                        {...register("period")}
                                        id="period"
                                        name="period"
                                        autoComplete="period-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        // onChange={(e) =>
                                        //     setPeriod(e.target.value)
                                        // }
                                    >
                                        <option>Manhã</option>
                                        <option>Tarde</option>
                                        <option>Integral</option>
                                    </select>
                                    {errors.period && (
                                        <span className="text-mainDarkRed">
                                            {errors.period.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="article"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Artigo
                                    </label>
                                    <input
                                        {...register("article")}
                                        type="text"
                                        name="article"
                                        id="article"
                                        autoComplete="article"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.article && (
                                        <span className="text-mainDarkRed">
                                            {errors.article.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="period"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Possui tabela de medidas?
                                    </label>
                                    <select
                                        {...register("has_table_measurement")}
                                        id="has_table_measurement"
                                        name="has_table_measurement"
                                        autoComplete="has_table_measurement-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            if (e.target.value == "Sim") {
                                                setPossuiMedidas(true);
                                            } else {
                                                setPossuiMedidas(false);
                                            }
                                            // setTable(e.target.value)
                                        }}
                                    >
                                        <option>Não</option>
                                        <option>Sim</option>
                                    </select>
                                    {errors.period && (
                                        <span className="text-mainDarkRed">
                                            {errors.period.message}
                                        </span>
                                    )}
                                </div>
                                {possuiMedidas ? (
                                    <>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="table_code"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Código da tabela
                                            </label>
                                            <input
                                                {...register("table_code")}
                                                type="text"
                                                name="table_code"
                                                id="table_code"
                                                autoComplete="table_code"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                value={tableCode}
                                                onChange={(e) => {
                                                    setTableCode(
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.table_code && (
                                                <span className="text-mainDarkRed">
                                                    {errors.table_code.message}
                                                </span>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="article_classification"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Classificação do Artigo
                                            </label>
                                            <select
                                                {...register(
                                                    "article_classification"
                                                )}
                                                id="article_classification"
                                                name="article_classification"
                                                autoComplete="article_classification-name"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                // onChange={(e) =>
                                                //     setArticleClassification(
                                                //         e.target.value
                                                //     )
                                                // }
                                            >
                                                <option>Feminino</option>
                                                <option>Masculino</option>
                                                <option>Infantil</option>
                                            </select>
                                            {errors.period && (
                                                <span className="text-mainDarkRed">
                                                    {errors.period.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="category"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Categoria do Produto
                                            </label>
                                            <select
                                                {...register("category")}
                                                id="category"
                                                name="category"
                                                autoComplete="category-name"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                            >
                                                <option>Top</option>
                                                <option>Botton</option>
                                                <option>Inteiro</option>
                                                <option>Acessório</option>
                                                <option>Esportivo</option>
                                                <option>Intima</option>
                                            </select>
                                            {errors.category && (
                                                <span className="text-mainDarkRed">
                                                    {errors.category.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="material"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Material
                                            </label>
                                            <select
                                                {...register("material")}
                                                id="material"
                                                name="material"
                                                autoComplete="material-name"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                            >
                                                <option>Malha</option>
                                                <option>Plano</option>
                                            </select>
                                            {errors.material && (
                                                <span className="text-mainDarkRed">
                                                    {errors.material.message}
                                                </span>
                                            )}
                                        </div>
                                    </>
                                )}
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="quantity"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Quantidade
                                    </label>
                                    <input
                                        {...register("quantity")}
                                        // type="number"
                                        name="quantity"
                                        id="quantity"
                                        value={quantity}
                                        onChange={(e) =>
                                            setQuantity(
                                                maskNumber(e.target.value)
                                            )
                                        }
                                        autoComplete="quantity"
                                        min="0"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.quantity && (
                                        <span className="text-mainDarkRed">
                                            {errors.quantity.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="level_of_pricing"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Nível de Precificação
                                    </label>
                                    <select
                                        {...register("level_of_pricing")}
                                        id="level_of_pricing"
                                        name="level_of_pricing"
                                        autoComplete="level_of_pricing-name"
                                        onChange={(e) =>
                                            setPricing(e.target.value)
                                        }
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    >
                                        <option>P1</option>
                                        <option>P2</option>
                                        <option>P3</option>
                                        <option>P4</option>
                                        <option>P5</option>
                                    </select>
                                    {errors.level_of_pricing && (
                                        <span className="text-mainDarkRed">
                                            {errors.level_of_pricing.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="level_of_pricing"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Regime de inspeção
                                    </label>
                                    <select
                                        {...register("level_avaliation")}
                                        id="level_avaliation"
                                        name="level_avaliation"
                                        autoComplete="level_avaliation-name"
                                        onChange={(e) =>
                                            setLevel(e.target.value)
                                        }
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    >
                                        <option
                                            value={"intermediario_atenuado"}
                                        >
                                            Intermediário e Atenuado
                                        </option>
                                        <option value={"normal"}>Normal</option>
                                        <option value={"severo"}>Severo</option>
                                    </select>
                                    {errors.level_avaliation && (
                                        <span className="text-mainDarkRed">
                                            {errors.level_avaliation.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="responsible"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Responsável
                                    </label>
                                    <input
                                        {...register("responsible")}
                                        type="text"
                                        name="responsible"
                                        id="responsible"
                                        autoComplete="responsible"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.responsible && (
                                        <span className="text-mainDarkRed">
                                            {errors.responsible.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="cargo"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Cargo
                                    </label>
                                    <input
                                        {...register("cargo")}
                                        type="text"
                                        name="cargo"
                                        id="cargo"
                                        autoComplete="cargo"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.cargo && (
                                        <span className="text-mainDarkRed">
                                            {errors.cargo.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="level_of_pricing"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Tipo do Produto
                                    </label>
                                    <select
                                        {...register("tipo_produto")}
                                        id="tipo_produto"
                                        name="tipo_produto"
                                        autoComplete="tipo_produto-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            if (e.target.value == "conjunto") {
                                                setIsClothingSet(true);
                                            } else {
                                                setIsClothingSet(false);
                                            }
                                        }}
                                    >
                                        <option value={`produto`}>
                                            Produto
                                        </option>
                                        <option value={`conjunto`}>
                                            Conjunto/Kit
                                        </option>
                                    </select>
                                </div>
                                {isClothingSet ? (
                                    <>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="clothingSet"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Conjunto/Kit
                                            </label>
                                            <select
                                                {...register("clothingSet")}
                                                id="clothingSet"
                                                name="clothingSet"
                                                autoComplete="clothingSet"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                            >
                                                <option value="">
                                                    Selecione
                                                </option>
                                                {listSelectClothingSet
                                                    ? listSelectClothingSet.map(
                                                          (clothing) => (
                                                              <option
                                                                  key={
                                                                      clothing.id
                                                                  }
                                                                  value={
                                                                      clothing.id
                                                                  }
                                                              >
                                                                  {
                                                                      clothing.name
                                                                  }
                                                              </option>
                                                          )
                                                      )
                                                    : ""}
                                            </select>
                                            {errors.clothingSet && (
                                                <span className="text-mainDarkRed">
                                                    {errors.clothingSet.message}
                                                </span>
                                            )}
                                        </div>
                                    </>
                                ) : (
                                    <>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="level_of_pricing"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Produtos
                                            </label>
                                            <select
                                                {...register("product")}
                                                id="product"
                                                name="product"
                                                autoComplete="product"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                            >
                                                <option value="">
                                                    Selecione
                                                </option>
                                                {listSelectProduct
                                                    ? listSelectProduct.map(
                                                          (product) => (
                                                              <option
                                                                  key={
                                                                      product.id
                                                                  }
                                                                  value={
                                                                      product.id
                                                                  }
                                                              >
                                                                  {product.name}
                                                              </option>
                                                          )
                                                      )
                                                    : ""}
                                            </select>
                                            {errors.product && (
                                                <span className="text-mainDarkRed">
                                                    {errors.product.message}
                                                </span>
                                            )}
                                        </div>
                                    </>
                                )}
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="packsAmount"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Quantidade de packs
                                    </label>
                                    <input
                                        {...register("packsAmount")}
                                        value={packQuantity}
                                        onChange={(e: any) => {
                                            setPackQuantity(
                                                maskNumber(e.target.value)
                                            );
                                            setValue(
                                                "packsAmount",
                                                e.target.value
                                            );
                                        }}
                                        // type="number"
                                        name="packsAmount"
                                        id="packsAmount"
                                        className=" mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    {errors.packsAmount && (
                                        <span className="text-mainDarkRed">
                                            {errors.packsAmount.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="quantityInspectedParts"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Quantidade de peças inspecionadas
                                    </label>
                                    <input
                                        // {...register("quantityInspectedParts")}
                                        value={piecesQuantity}
                                        // onChange={(e) =>
                                        //     setPiecesQuantity(
                                        //         maskNumber(e.target.value)
                                        //     )
                                        // }
                                        // type="number"
                                        name="quantityInspectedParts"
                                        id="quantityInspectedParts"
                                        className="bg-gray-200 mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        disabled
                                    />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="kindOfPacking"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Tipo de embalagem
                                    </label>
                                    <input
                                        {...register("kindOfPacking")}
                                        type="text"
                                        name="kindOfPacking"
                                        id="kindOfPacking"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="bonusOrder"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Pedido bonificado
                                    </label>
                                    <input
                                        {...register("bonusOrder")}
                                        type="text"
                                        name="bonusOrder"
                                        id="bonusOrder"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                </div>
                                {
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="clientId"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Selecione o cliente
                                        </label>
                                        <input
                                            value={searchClients}
                                            ref={ref}
                                            type="text"
                                            name="clientId"
                                            id="clientId"
                                            autoComplete="off"
                                            onChange={(e) =>
                                                setSearchClients(e.target.value)
                                            }
                                            onClick={() => {
                                                setSuggestionActive(
                                                    !suggestionActive
                                                );
                                            }}
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        />{" "}
                                        {isClientSelected === "notSelected" && (
                                            <div className="text-red-700">
                                                {"Por favor escolha o cliente"}
                                            </div>
                                        )}
                                        <div
                                            className={`bg-gray-400 rounded-md w-64 ${
                                                suggestionActive
                                                    ? "flex flex-col"
                                                    : "hidden"
                                            }`}
                                        >
                                            {listClients &&
                                                listClients.length > 0 &&
                                                listClients.map(
                                                    (
                                                        clients: any,
                                                        index: any
                                                    ) => (
                                                        <div
                                                            className="w-full bg-transparent mt-[-1px] text-black rounded-md hover:bg-red-600"
                                                            key={clients.id}
                                                        >
                                                            <div
                                                                className="flex justify-between gap-20 p-4 cursor-pointer text-white"
                                                                onClick={() => {
                                                                    setClientId(
                                                                        clients.id
                                                                    );
                                                                    setSearchClients(
                                                                        clients.companyName
                                                                    );
                                                                }}
                                                            >
                                                                {clients.companyName !==
                                                                undefined
                                                                    ? clients.companyName
                                                                    : "Não há consultas disponíveis no momento"}
                                                            </div>
                                                        </div>
                                                    )
                                                )}
                                        </div>
                                    </div>
                                }
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="productDescription"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Descrição de produtos
                                    </label>
                                    <textarea
                                        {...register("productDescription")}
                                        // type="text"
                                        name="productDescription"
                                        id="productDescription"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="level_of_pricing"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Fabrica
                                    </label>
                                    <select
                                        {...register("fabrica")}
                                        id="fabrica"
                                        name="fabrica"
                                        autoComplete="fabrica"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue("fabrica", e.target.value);
                                            setSelectedFactory(e.target.value);
                                        }}
                                    >
                                        <option value="">Selecione</option>
                                        {factories
                                            ? factories.map((factory) => (
                                                  <option
                                                      key={factory.id}
                                                      value={factory.id}
                                                  >
                                                      {factory.fullname}
                                                  </option>
                                              ))
                                            : ""}
                                    </select>
                                    {errors.fabrica && (
                                        <span className="text-mainDarkRed">
                                            {errors.fabrica.message}
                                        </span>
                                    )}
                                </div>

                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="registrationCode"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Código de Cadastro
                                    </label>
                                    <input
                                        value={registrationCode}
                                        type="text"
                                        name="registrationCode"
                                        id="registrationCode"
                                        autoComplete="registrationCode"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm bg-gray-200"
                                        disabled={true}
                                    />
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="supplierCode"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Código do fornecedor
                                    </label>
                                    <input
                                        value={supplierCode}
                                        type="text"
                                        name="supplierCode"
                                        id="supplierCode"
                                        autoComplete="supplierCode"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm bg-gray-200"
                                        disabled={true}
                                    />
                                </div>

                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label
                                        htmlFor="zip_code"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        CEP
                                    </label>
                                    <input
                                        {...register("zip_code")}
                                        type="text"
                                        name="zip_code"
                                        id="zip_code"
                                        autoComplete="zip_code"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            const formated = handleChangeMask(
                                                e.target.value,
                                                "cep"
                                            );

                                            setValue(
                                                "zip_code",
                                                e.target.value
                                            );
                                            e.target.value = formated;
                                            completeAddress(formated);
                                        }}
                                    />
                                    {errors.zip_code && (
                                        <span className="text-mainDarkRed">
                                            {errors.zip_code.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-6 lg:col-span-4">
                                    <label
                                        htmlFor="address"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Endereço
                                    </label>
                                    <input
                                        {...register("address")}
                                        type="text"
                                        name="address"
                                        id="address"
                                        autoComplete="address"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue("address", e.target.value);
                                        }}
                                    />
                                    {errors.address && (
                                        <span className="text-mainDarkRed">
                                            {errors.address.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                    <label
                                        htmlFor="number"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Número
                                    </label>
                                    <input
                                        {...register("number")}
                                        type="text"
                                        name="number"
                                        id="number"
                                        autoComplete="number"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue("number", e.target.value);
                                        }}
                                    />
                                    {errors.number && (
                                        <span className="text-mainDarkRed">
                                            {errors.number.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="district"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Bairro
                                    </label>
                                    <input
                                        {...register("district")}
                                        type="text"
                                        name="district"
                                        id="district"
                                        autoComplete="district"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue(
                                                "district",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.district && (
                                        <span className="text-mainDarkRed">
                                            {errors.district.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3">
                                    <label
                                        htmlFor="description"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Ponto de referência
                                    </label>
                                    <input
                                        {...register("description")}
                                        type="text"
                                        name="description"
                                        id="description"
                                        autoComplete="description"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue(
                                                "description",
                                                e.target.value
                                            );
                                        }}
                                    />
                                    {errors.description && (
                                        <span className="text-mainDarkRed">
                                            {errors.description.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                    <label
                                        htmlFor="city"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Cidade
                                    </label>
                                    <input
                                        {...register("city")}
                                        type="text"
                                        name="city"
                                        id="city"
                                        autoComplete="address-level2"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue("city", e.target.value);
                                        }}
                                    />
                                    {errors.city && (
                                        <span className="text-mainDarkRed">
                                            {errors.city.message}
                                        </span>
                                    )}
                                </div>
                                <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                    <label
                                        htmlFor="state"
                                        className="block text-sm font-medium text-gray-700"
                                    >
                                        Estado
                                    </label>
                                    <select
                                        {...register("state")}
                                        id="state"
                                        name="state"
                                        autoComplete="state-name"
                                        className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                        onChange={(e) => {
                                            setValue("state", e.target.value);
                                        }}
                                    >
                                        <option value={`AC`}>AC</option>
                                        <option value={`AL`}>AL</option>
                                        <option value={`AP`}>AP</option>
                                        <option value={`AM`}>AM</option>
                                        <option value={`BA`}>BA</option>
                                        <option value={`CE`}>CE</option>
                                        <option value={`DF`}>DF</option>
                                        <option value={`ES`}>ES</option>
                                        <option value={`GO`}>GO</option>
                                        <option value={`MA`}>MA</option>
                                        <option value={`MT`}>MT</option>
                                        <option value={`MS`}>MS</option>
                                        <option value={`MG`}>MG</option>
                                        <option value={`PA`}>PA</option>
                                        <option value={`PB`}>PB</option>
                                        <option value={`PR`}>PR</option>
                                        <option value={`PE`}>PE</option>
                                        <option value={`PI`}>PI</option>
                                        <option value={`RJ`}>RJ</option>
                                        <option value={`RN`}>RN</option>
                                        <option value={`RS`}>RS</option>
                                        <option value={`RO`}>RO</option>
                                        <option value={`RR`}>RR</option>
                                        <option value={`SC`}>SC</option>
                                        <option value={`SP`}>SP</option>
                                        <option value={`SE`}>SE</option>
                                        <option value={`TO`}>TO</option>
                                    </select>
                                    {errors.state && (
                                        <span className="text-mainDarkRed">
                                            {errors.state.message}
                                        </span>
                                    )}
                                </div>
                                {
                                    <div className="col-span-6 sm:col-span-3">
                                        <label
                                            htmlFor="level_of_pricing"
                                            className="block text-sm font-medium text-gray-700"
                                        >
                                            Auditor
                                        </label>
                                        <select
                                            {...register("auditor")}
                                            id="auditor"
                                            name="auditor"
                                            autoComplete="auditor"
                                            className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                            onChange={(e) => {
                                                setValue(
                                                    "auditor",
                                                    e.target.value
                                                );
                                            }}
                                        >
                                            <option value="">Selecione</option>
                                            {listAuditor
                                                ? listAuditor.map((auditor) => (
                                                      <option
                                                          key={auditor.id}
                                                          value={auditor.id}
                                                      >
                                                          {auditor.fullname}
                                                      </option>
                                                  ))
                                                : ""}
                                        </select>
                                        {errors.auditor && (
                                            <span className="text-mainDarkRed">
                                                {errors.auditor.message}
                                            </span>
                                        )}
                                    </div>
                                }
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-6 w-full flex justify-center md:justify-end gap-3">
                    <div
                        className="w-full md:w-32"
                        onClick={() => history.goBack()}
                    >
                        <SecondaryButton title="Voltar" />
                    </div>
                    <div className="w-full md:w-32">
                        <PrimaryButton
                            title="Salvar"
                            form="userRegisterForm"
                            isLoading={isLoading}
                            onClick={() => {
                                searchClients !== ""
                                    ? setIsClientSelected("selected")
                                    : setIsClientSelected("notSelected");
                            }}
                        />
                    </div>
                </div>
            </div>
            <AlertModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                confirmButton={modal.customModal.confirmButton}
                path="/"
            />
        </>
    );
}
