import { useParams } from "react-router-dom";
import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
//@ts-ignore
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import { useEffect, useState } from "react";
import {
    handleChangeMask,
    maskNumber,
    maskPhone,
} from "../../services/utils/mask";
import Toggle from "../../components/Toggle";

import { addressByCep } from "../../services/utils/getAddressByCep";

interface RegistrationProps {
    companyName: string;
    email: string;
    password: string;
    confirmPassword: string;
    cnpj: string;
    phone: string;
    // accept_term: boolean;
    location: string;

    zip_code: string;
    number: string;
    district: string;
    description: string;
    city: string;
    state: string;
}

export default function FactoryRegistration() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    // const [acceptTermState, setAcceptTermState] = useState<boolean>(false);
    const [password, setPassword] = useState<string>("");
    const [cnpj, setCnpj] = useState<string>("");
    const [cep, setCep] = useState<string>("");
    const [addressId, setAddressId] = useState<string>("");
    const modal = useCustomModal();
    const [aditionalValid, setAditionalValid] = useState<boolean>(true);

    let { id }: { id: string } = useParams();

    const schema = yup.object().shape({
        phone: yup.string().required("Campo obrigatório"),
        companyName: yup.string().required("Campo obrigatório"),
        cnpj: yup
            .string()
            .required("Campo obrigatório")
            .min(18, "Mínimo 18 caracteres"),
        // // .max(18, "Máximo 18 caracteres"),
        email: yup
            .string()
            .required("Campo obrigatório")
            .email("Digite um e-mail válido"),
        // accept_term: yup.boolean(),
        password: id
            ? password.length > 0
                ? yup
                      .string()
                      .matches(
                          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])/,
                          "A senha deve ter no minimo 12 caracteres, letras minusculas e maiusculas e caracteres especiais."
                      )
                      .min(12, "Mínimo 12 digitos")
                : yup.string()
            : yup
                  .string()
                  .required("Senha obrigatória")
                  .matches(
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])/,
                      "A senha deve ter no minimo 12 caracteres, letras minusculas e maiusculas e caracteres especiais."
                  )
                  .min(12, "Mínimo 12 digitos"),
        confirmPassword: id
            ? yup.string()
            : yup
                  .string()
                  .required("Confirme a senha")
                  .oneOf(
                      [yup.ref("password"), null],
                      "As senhas precisam ser iguais"
                  ),

        location: yup.string().required("Campo obrigatório"),
        number: yup
            .string()
            .required("Campo obrigatório")
            .max(10, "Máximo 10 caracteres"),
        district: yup
            .string()
            .required("Campo obrigatório")
            .max(150, "Máximo 150 caracteres"),
        description: yup.string().max(150, "Máximo 150 caracteres"),
        city: yup
            .string()
            .required("Campo obrigatório")
            .max(150, "Máximo 150 caracteres"),
        state: yup
            .string()
            .required("Campo obrigatório")
            .max(2, "Máximo 2 caracteres"),
        zip_code: yup
            .string()
            .required("Campo obrigatório")
            .min(9, "Mínimo 9 caracteres"),
    });

    useEffect(() => {
        async function getCustomer() {
            const { data } = await api.get(`clients/${id}`);
            console.log("ta vindo isso ->", data);
            setValue("phone", maskPhone(data.phone));
            setValue("companyName", data.companyName);
            setValue("cnpj", data.cnpj);
            setValue("email", data.email);
            // setValue("accept_term", data.accept_term);
            // setAcceptTermState(data.accept_term);

            setAddressId(data.address_id);
            setValue("zip_code", data.address.zip_code);
            setValue("location", data.address.address);
            setValue("number", data.address.number);
            setValue("city", data.address.city);
            setValue("state", data.address.state);
            setValue("district", data.address.district);
            setValue("description", data.address.description);
            setAditionalValid(data.impactTheResult ? true : false);
        }

        getCustomer();
    }, []);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<RegistrationProps>({
        resolver: yupResolver(schema),
    });

    async function handleRegisterCustomer(data: RegistrationProps) {
        setIsLoading(true);
        const dataRegistration = {
            phone: maskNumber(data.phone),
            companyName: data.companyName,
            email: data.email,
            password: data.password,
            // state_registration: data.state_registration,
            cnpj: data.cnpj,
            // accept_term: data.accept_term,
            impactTheResult: aditionalValid,
            address: {
                zip_code: data.zip_code,
                address: data.location,
                number: data.number,
                district: data.district,
                description: data.description,
                city: data.city,
                state: data.state,
            },
        } as any;

        try {
            let response;

            if (id) {
                if (!dataRegistration.password) {
                    delete dataRegistration.password;
                }

                dataRegistration.address_id = addressId;
                response = await api.put(`clients/${id}`, dataRegistration);
            } else {
                response = await api.post("clients", dataRegistration);
            }

            if (response.data.status === "Error") {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível realizar seu cadastro",
                    text: response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
            } else {
                modal.setCustomModal({
                    status: true,
                    icon: "success",
                    title: "Cliente cadastrado/editado com sucesso!",
                    text: "Agora você pode acessá-lo na área de clientes",
                    cancelButton: "",
                    confirmButton: "Ok",
                });
            }
            setIsLoading(false);
        } catch (e: any) {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Um erro inesperado aconteceu",
                text: e.response.data.message,
                cancelButton: "",
                confirmButton: "",
            });
            setIsLoading(false);
        }
    }

    async function completeAddress(cep: string) {
        if (cep.length === 9) {
            let cepSearch = cep.replace("-", "");
            const address = await addressByCep(cepSearch);

            if (address.logradouro) {
                setValue("location", address.logradouro);
                setValue("number", address.number);
                setValue("district", address.bairro);
                setValue("description", address.complemento);
                setValue("city", address.localidade);
                setValue("state", address.uf);
            }
        }
    }

    return (
        <>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Cadastro de clientes
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Área de cadastro de clientes
                        </p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form
                            action="#"
                            method="POST"
                            id="customerRegisterForm"
                            onSubmit={handleSubmit(handleRegisterCustomer)}
                        >
                            <div className=" sm:overflow-hidden">
                                <div className="bg-white py-6  space-y-6 sm:p-6">
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="companyName"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Nome do cliente{" "}
                                            </label>
                                            <input
                                                {...register("companyName")}
                                                type="string"
                                                name="companyName"
                                                id="companyName"
                                                autoComplete="companyName"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "companyName",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.companyName && (
                                                <span className="text-mainDarkRed">
                                                    {errors.companyName.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="phone"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Telefone
                                            </label>
                                            <input
                                                {...register("phone")}
                                                type="text"
                                                name="phone"
                                                id="phone"
                                                autoComplete="phone"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "phone",
                                                        maskPhone(
                                                            e.target.value
                                                        )
                                                    );
                                                }}
                                            />
                                            {errors.phone && (
                                                <span className="text-mainDarkRed">
                                                    {errors.phone.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="cnpj"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                CNPJ
                                            </label>
                                            <input
                                                {...register("cnpj")}
                                                type="text"
                                                name="cnpj"
                                                id="cnpj"
                                                autoComplete="cnpj"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    const formated =
                                                        handleChangeMask(
                                                            e.target.value,
                                                            "cnpj"
                                                        );
                                                    setCnpj(formated);
                                                    setValue(
                                                        "cnpj",
                                                        e.target.value
                                                    );
                                                    e.target.value = formated;
                                                }}
                                            />
                                            {errors.cnpj && (
                                                <span className="text-mainDarkRed">
                                                    {errors.cnpj.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="cnpj"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Recusado por perguntas
                                                adicionais?
                                            </label>
                                            <div
                                                className={`h-10 flex gap-2 mt-1 w-full justify-start items-center`}
                                            >
                                                <Toggle
                                                    state={aditionalValid}
                                                    setState={setAditionalValid}
                                                />
                                                {aditionalValid ? (
                                                    <div className="text-mainDarkRed">
                                                        Sim
                                                    </div>
                                                ) : (
                                                    <div className="text-mainGray">
                                                        Não
                                                    </div>
                                                )}
                                            </div>
                                        </div>

                                        <div className="col-span-6 sm:col-span-4">
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                E-mail
                                            </label>
                                            <input
                                                {...register("email")}
                                                type="email"
                                                name="email"
                                                id="email"
                                                autoComplete="email"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "email",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.email && (
                                                <span className="text-mainDarkRed">
                                                    {errors.email.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="password"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {id ? "Mudar a senha" : "Senha"}
                                            </label>
                                            <input
                                                {...register("password")}
                                                type="password"
                                                name="password"
                                                id="password"
                                                autoComplete="password"
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                            />
                                            {errors.password && (
                                                <span className="text-mainDarkRed">
                                                    {errors.password.message}
                                                </span>
                                            )}
                                        </div>

                                        {id ? (
                                            ""
                                        ) : (
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="confirmPassword"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    Confirme sua senha
                                                </label>
                                                <input
                                                    {...register(
                                                        "confirmPassword"
                                                    )}
                                                    type="password"
                                                    name="confirmPassword"
                                                    id="confirmPassword"
                                                    autoComplete="confirm-password"
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                />
                                                {errors.confirmPassword && (
                                                    <span className="text-mainDarkRed">
                                                        {
                                                            errors
                                                                .confirmPassword
                                                                .message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        )}

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label
                                                htmlFor="zip_code"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                CEP
                                            </label>
                                            <input
                                                {...register("zip_code")}
                                                type="text"
                                                name="zip_code"
                                                id="zip_code"
                                                autoComplete="zip_code"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    const formated =
                                                        handleChangeMask(
                                                            e.target.value,
                                                            "cep"
                                                        );
                                                    setCep(formated);
                                                    setValue(
                                                        "zip_code",
                                                        e.target.value
                                                    );
                                                    e.target.value = formated;
                                                    completeAddress(formated);
                                                }}
                                            />
                                            {errors.zip_code && (
                                                <span className="text-mainDarkRed">
                                                    {errors.zip_code.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-span-6 sm:col-span-6 lg:col-span-4">
                                            <label
                                                htmlFor="location"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Endereço
                                            </label>
                                            <input
                                                {...register("location")}
                                                type="text"
                                                name="location"
                                                id="location"
                                                autoComplete="location"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "location",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.location && (
                                                <span className="text-mainDarkRed">
                                                    {errors.location.message}
                                                </span>
                                            )}
                                        </div>
                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                            <label
                                                htmlFor="number"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Número
                                            </label>
                                            <input
                                                {...register("number")}
                                                type="text"
                                                name="number"
                                                id="number"
                                                autoComplete="number"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "number",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.number && (
                                                <span className="text-mainDarkRed">
                                                    {errors.number.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-span-6 sm:col-span-6 lg:col-span-2">
                                            <label
                                                htmlFor="city"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Cidade
                                            </label>
                                            <input
                                                {...register("city")}
                                                type="text"
                                                name="city"
                                                id="city"
                                                autoComplete="address-level2"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "city",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.city && (
                                                <span className="text-mainDarkRed">
                                                    {errors.city.message}
                                                </span>
                                            )}
                                        </div>

                                        {/* <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label
                                                htmlFor="state"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Estado
                                            </label>
                                            <input
                                                {...register("state")}
                                                type="text"
                                                name="state"
                                                id="state"
                                                autoComplete="state"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                maxLength={2}
                                            />
                                            {errors.state && (
                                                <span className="text-mainDarkRed">
                                                    {errors.state.message}
                                                </span>
                                            )}
                                        </div> */}

                                        <div className="col-span-6 sm:col-span-3 lg:col-span-2">
                                            <label
                                                htmlFor="state"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Estado
                                            </label>
                                            <select
                                                {...register("state")}
                                                id="state"
                                                name="state"
                                                autoComplete="state-name"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "state",
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                <option value={`AC`}>AC</option>
                                                <option value={`AL`}>AL</option>
                                                <option value={`AP`}>AP</option>
                                                <option value={`AM`}>AM</option>
                                                <option value={`BA`}>BA</option>
                                                <option value={`CE`}>CE</option>
                                                <option value={`DF`}>DF</option>
                                                <option value={`ES`}>ES</option>
                                                <option value={`GO`}>GO</option>
                                                <option value={`MA`}>MA</option>
                                                <option value={`MT`}>MT</option>
                                                <option value={`MS`}>MS</option>
                                                <option value={`MG`}>MG</option>
                                                <option value={`PA`}>PA</option>
                                                <option value={`PB`}>PB</option>
                                                <option value={`PR`}>PR</option>
                                                <option value={`PE`}>PE</option>
                                                <option value={`PI`}>PI</option>
                                                <option value={`RJ`}>RJ</option>
                                                <option value={`RN`}>RN</option>
                                                <option value={`RS`}>RS</option>
                                                <option value={`RO`}>RO</option>
                                                <option value={`RR`}>RR</option>
                                                <option value={`SC`}>SC</option>
                                                <option value={`SP`}>SP</option>
                                                <option value={`SE`}>SE</option>
                                                <option value={`TO`}>TO</option>
                                            </select>
                                            {errors.state && (
                                                <span className="text-mainDarkRed">
                                                    {errors.state.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="district"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Bairro
                                            </label>
                                            <input
                                                {...register("district")}
                                                type="text"
                                                name="district"
                                                id="district"
                                                autoComplete="district"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "district",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.district && (
                                                <span className="text-mainDarkRed">
                                                    {errors.district.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="description"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Ponto de referência
                                            </label>
                                            <input
                                                {...register("description")}
                                                type="text"
                                                name="description"
                                                id="description"
                                                autoComplete="description"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "description",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.description && (
                                                <span className="text-mainDarkRed">
                                                    {errors.description.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>

                <div className="mt-6 w-full flex justify-center md:justify-end gap-3">
                    <div
                        className="w-full md:w-32"
                        onClick={() => history.goBack()}
                    >
                        <SecondaryButton title="Voltar" />
                    </div>
                    <div className="w-full md:w-32">
                        <PrimaryButton
                            title="Salvar"
                            form="customerRegisterForm"
                            isLoading={isLoading}
                        />
                    </div>
                </div>
            </div>
            <AlertModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                confirmButton={modal.customModal.confirmButton}
                path="/clientes"
            />
        </>
    );
}
