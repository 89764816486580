import { useContext } from "react";
import { Switch } from "react-router-dom";
import AddProductToClothing from "../../pages/AddProductToClothing";
import Auditores from "../../pages/Auditores";
import AuditorRegistration from "../../pages/AuditorRegistration";
import ClothingSet from "../../pages/ClothingSet";
import ClothingSetProducts from "../../pages/ClothingSetProducts";
import ClothingSetRegistration from "../../pages/ClothingSetRegistration";
import Customers from "../../pages/Customer";
import CustomerRegistration from "../../pages/CustomerRegistration";

import Dashboard from "../../pages/Dashboard";
import Defects from "../../pages/Defects";
import DefectsByProduct from "../../pages/DefectsByProduct";
import DefectsRegistration from "../../pages/DefectsRegistration";
import DefectsToProducts from "../../pages/DefectsToProducts";
import Factories from "../../pages/Factories";
import FactoryRegistration from "../../pages/FactoryRegistration";
import Parameters from "../../pages/Parameters";
import ParamsRegistration from "../../pages/ParamsRegistration";
import PreAuditoriaAnswer from "../../pages/PreAuditoriaAnswer";
import ProductRegistration from "../../pages/ProductRegistration";
import Products from "../../pages/Products";
import ExtraQuestions from "../../pages/ExtraQuestions";
import ExtraQuestionsRegistration from "../../pages/ExtraQuestionsRegistration";
import SolicitationEdit from "../../pages/SolicitationEdit";
import SolicitationForm from "../../pages/SolicitationRegistration";

import UserRegistration from "../../pages/UserRegistration";
import Users from "../../pages/Users";
import AdminRoute from "../AdminRoute";
import Reports from "../../pages/Reports";

const AuthenticatedRoute = () => (
    <Switch>
        <AdminRoute exact path="/" component={() => <Dashboard />} />

        <AdminRoute path="/usuarios" component={() => <Users />} />
        <AdminRoute exact path="/reports" component={() => <Reports />} />
        <AdminRoute
            path="/usuario-registrar"
            component={() => <UserRegistration />}
        />
        <AdminRoute
            path="/usuario-editar/:id"
            component={() => <UserRegistration />}
        />
        <AdminRoute
            path="/solicitacao-formulario"
            component={() => <SolicitationForm />}
        />

        <AdminRoute path="/produtos" component={() => <Products />} />

        <AdminRoute
            path="/produtos-registrar"
            component={() => <ProductRegistration />}
        />
        <AdminRoute
            path="/produtos-editar/:id"
            component={() => <ProductRegistration />}
        />

        <AdminRoute path="/fabricas" component={() => <Factories />} />
        <AdminRoute path="/clientes" component={() => <Customers />} />

        <AdminRoute
            path="/fabricas-registrar"
            component={() => <FactoryRegistration />}
        />
        <AdminRoute
            path="/fabricas-editar/:id"
            component={() => <FactoryRegistration />}
        />

        <AdminRoute
            path="/clientes-registrar"
            component={() => <CustomerRegistration />}
        />
        <AdminRoute
            path="/clientes-editar/:id"
            component={() => <CustomerRegistration />}
        />

        <AdminRoute
            path="/conjuntos-produtos"
            component={() => <ClothingSet />}
        />
        <AdminRoute
            path="/conjuntos-produtos-cadastrar"
            component={() => <ClothingSetRegistration />}
        />
        <AdminRoute
            path="/conjunto-produtos/:id"
            component={() => <ClothingSetProducts />}
        />

        <AdminRoute path="/auditores" component={() => <Auditores />} />

        <AdminRoute
            path="/auditores-registrar"
            component={() => <AuditorRegistration />}
        />
        <AdminRoute
            path="/auditores-editar/:id"
            component={() => <AuditorRegistration />}
        />

        <AdminRoute
            path="/produtos-parametros/:id"
            component={() => <Parameters />}
        />

        <AdminRoute
            path="/produtos-add-parametros/:idProduct"
            component={() => <ParamsRegistration />}
        />
        <AdminRoute
            path="/produtos-edit-parametros/:idProduct/:id"
            component={() => <ParamsRegistration />}
        />

        <AdminRoute path="/defeitos" component={() => <Defects />} />
        <AdminRoute
            path="/defeitos-registrar"
            component={() => <DefectsRegistration />}
        />

        <AdminRoute
            path="/defeitos-editar/:id"
            component={() => <DefectsRegistration />}
        />

        <AdminRoute
            path="/produtos-defeitos/:id"
            component={() => <DefectsByProduct />}
        />
        <AdminRoute
            path="/produtos-vincular-defeitos/:id"
            component={() => <DefectsToProducts />}
        />
        <AdminRoute
            path="/extraQuestions/"
            component={() => <ExtraQuestions />}
        />
        <AdminRoute
            path="/extraQuestionsAdd"
            component={() => <ExtraQuestionsRegistration />}
        />
        <AdminRoute
            path="/extraQuestionsEdit/:id"
            component={() => <ExtraQuestionsRegistration />}
        />
        <AdminRoute
            path="/solicitation/edit/:id"
            component={() => <SolicitationEdit />}
        />
        <AdminRoute
            path="/conjunto-adicionar-produto/:id"
            component={() => <AddProductToClothing />}
        />

        <AdminRoute
            exact
            path="/resultadoauditoria/:id"
            component={() => <PreAuditoriaAnswer />}
        />

        {/* caso a rota não exista */}
        {/* <AdminRoute path="*" component={() => <Redirect to="/" />} /> */}
    </Switch>
);

export default AuthenticatedRoute;
