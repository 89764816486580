import { useContext, useState } from "react";
import InfoContext from "../../context/InfoContext";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import { ClothingSetProductProps } from "../../types/ClothingSet";
import { ProductsProps } from "../../types/Products";
import { ActionModal } from "../ActionModal";
import EmptyList from "../EmptyList";
import "./styles.css";

type TableProps = {
    list: ClothingSetProductProps[];
};

export default function ClothingProductTable({ list }: TableProps) {
    const modal = useCustomModal();
    const [itemId, setItemId] = useState<string>("");
    const { reloadData, setReloadData } = useContext(InfoContext);

    async function removeUser(id: string) {
        await api.delete(`products/delete/${id}`);
        // await setIsOpen(false);
        setReloadData(!reloadData);
    }

    return (
        <body className="flex items-center justify-center">
            <div className="container">
                <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
                    <thead className="text-white">
                        {list &&
                            list.map((item) => (
                                <tr className="bg-mainGray flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                    <th className="p-3 text-left">Nome</th>
                                    <th className="p-3 text-left">Tipo</th>
                                    <th className="p-3 text-left">Tecido</th>
                                    <th className="p-3 text-left w-[110px]">
                                        Ações
                                    </th>
                                </tr>
                            ))}
                    </thead>
                    <tbody className="flex-1 sm:flex-none">
                        {list.length > 0 ? (
                            list.map((item) => (
                                <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 bg-white">
                                    <td className="border-grey-light border hover:bg-gray-100 p-3">
                                        {item.product.name}
                                    </td>
                                    <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                                        {item.product.type_piece}
                                    </td>
                                    <td className="border-grey-light border hover:bg-gray-100 p-3 truncate">
                                        {item.product.type_tissue}
                                    </td>
                                    <td
                                        className="border-grey-light border hover:bg-gray-100 p-3 text-mainDarkRed hover:text-red-600 hover:font-medium cursor-pointer"
                                        onClick={() => {
                                            modal.setCustomModal({
                                                status: true,
                                                icon: "alert",
                                                title: "Excluir!",
                                                text: "Você tem certeza que deseja excluir esse produto?",
                                                cancelButton: "Cancelar",
                                                confirmButton: "",
                                            });
                                            setItemId(item.id.toString());
                                        }}
                                    >
                                        Delete
                                    </td>
                                </tr>
                            ))
                        ) : (
                            <EmptyList />
                        )}
                    </tbody>
                </table>
            </div>
            <ActionModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                action={removeUser}
                itemId={itemId}
            />
        </body>
    );
}
