import { useContext, useState } from "react";
import { useParams } from "react-router-dom";
import InfoContext from "../../context/InfoContext";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import { DefectProps } from "../../types/Defects";

import { ParametersListProps } from "../../types/Parameters";
import { ActionModal } from "../ActionModal";
import EmptyList from "../EmptyList";
import "./styles.css";
import {
    MdOutlineAssignmentReturned,
    MdOutlineAssignmentTurnedIn,
    MdOutlineLocationOn,
    MdOutlineCancel,
    MdEdit,
    MdInfoOutline,
    MdOutlineAssignment,
    MdOutlineHighlightOff,
    MdOutlineContactSupport,
} from "react-icons/md";

import Swal from "sweetalert2";

type TableProps = {
    list: DefectProps[];
};

export default function DefectByIdTable({ list }: TableProps) {
    const modal = useCustomModal();
    const [itemId, setItemId] = useState<string>("");
    const { reloadData, setReloadData } = useContext(InfoContext);

    async function removeUser(id: string) {
        await api.delete(`defects/delete/${id}`);
        // await setIsOpen(false);
        setReloadData(!reloadData);
    }
    async function removeDefectToProduct(defect_id: string) {
        const data = { idProduct: id, idDefect: defect_id };
        await api.post("products/relation/product/defect/remove", data);
        // await setIsOpen(false);
        Swal.fire({
            icon: "success",
            title: "Sucesso",
            showDenyButton: true,
            showCloseButton: false,
            showConfirmButton: false,
            denyButtonText: `Fechar`,
            text: "defeito removido com sucesso!",
        });
        setReloadData(!reloadData);
    }

    let { id }: { id: string } = useParams();

    return (
        <>
            <body className="flex items-center justify-center">
                <div className="container hidden sm:block">
                    <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
                        <thead className="text-white">
                            {list &&
                                list.map((item) => (
                                    <tr className="bg-mainGray flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                        <th className="p-3 text-left">Nome</th>
                                        <th className="p-3 text-left">
                                            Tipo do defeito
                                        </th>
                                        <th className="p-3 text-left">
                                            Categoria do defeito
                                        </th>
                                        {/* <th className="p-3 text-left">Telefone</th> */}
                                        <th className="p-3 text-left w-[110px]">
                                            Ações
                                        </th>
                                    </tr>
                                ))}
                        </thead>
                        <tbody className="flex-1 sm:flex-none">
                            {list.length > 0 ? (
                                list.map((item) => (
                                    <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 bg-white">
                                        <td className="border-grey-light border p-3">
                                            {item.defect.name_defect}
                                        </td>
                                        <td className="border-grey-light border p-3 truncate">
                                            {item.defect.type_defect}
                                        </td>
                                        <td className="border-grey-light border p-3 truncate">
                                            {item.defect.specific_defect}
                                        </td>

                                        <td
                                            className="border-grey-light border transition-all duration-500 p-3 hover:text-mainDarkRed text-mainGray hover:font-medium cursor-pointer"
                                            onClick={() => {
                                                modal.setCustomModal({
                                                    status: true,
                                                    icon: "alert",
                                                    title: "Excluir!",
                                                    text: "Você tem certeza que deseja excluir esse defeito?",
                                                    cancelButton: "Cancelar",
                                                    confirmButton: "",
                                                });
                                                setItemId(
                                                    item.defect.id.toString()
                                                );
                                            }}
                                        >
                                            <MdOutlineHighlightOff size="1.3em" />
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <EmptyList />
                            )}
                        </tbody>
                    </table>
                </div>
                <ActionModal
                    type={modal.customModal.icon}
                    title={modal.customModal.title}
                    description={modal.customModal.text}
                    isOpen={modal.customModal.status}
                    setIsOpen={modal.handleCustomModalClose}
                    action={removeDefectToProduct}
                    itemId={itemId}
                />
            </body>
            <div>
                {list &&
                    list.map((item) => (
                        <div className="flex justify-center h-full rounded-xl border border-gray-200 bg-white sm:hidden mt-4 overflow-hidden ">
                            <div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Nome
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Tipo do defeito
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Categoria do defeito
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Ações
                                </div>
                            </div>
                            <div className="w-full">
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    {item.defect.name_defect}
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    {item.defect.type_defect}
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    {item.defect.specific_defect}
                                </div>
                                <div className="border-grey-light text-mainGray border-t h-12 flex justify-start items-center px-2">
                                    <div
                                        className=" transition-all duration-500 hover:text-mainDarkRed text-mainGray hover:font-medium cursor-pointer"
                                        onClick={() => {
                                            modal.setCustomModal({
                                                status: true,
                                                icon: "alert",
                                                title: "Excluir!",
                                                text: "Você tem certeza que deseja excluir esse defeito?",
                                                cancelButton: "Cancelar",
                                                confirmButton: "",
                                            });
                                            setItemId(
                                                item.defect.id.toString()
                                            );
                                        }}
                                    >
                                        <MdOutlineHighlightOff size="1.3em" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
}
