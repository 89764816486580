import { useParams } from "react-router-dom";
import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";
import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import ReactLoading from "react-loading";
import { useEffect, useState } from "react";

interface RegistrationProps {
    fullname: string;
    email: string;
    password: string;
    confirmPassword: string;
    department: string;
    cargo: string;
}

export default function UserRegistration() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [cargo, setCargo] = useState<string>("Operação");
    const [loading, setLoading] = useState(false);
    const modal = useCustomModal();
    const [password, setPassword] = useState<string>("");

    let { id }: { id: string } = useParams();

    const schema = yup.object().shape({
        fullname: yup
            .string()
            .required("Nome é obrigatório")
            .min(3, "Mínimo 3 caracteres"),
        email: yup
            .string()
            .email("Digite um e-mail válido")
            .required("E-mail obrigatório"),
        password: id
            ? password.length > 0
                ? yup
                      .string()
                      .matches(
                          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])/,
                          "A senha deve ter no minimo 12 caracteres, letras minusculas e maiusculas e caracteres especiais."
                      )
                      .min(12, "Mínimo 12 digitos")
                      .oneOf(
                          [yup.ref("password"), null],
                          "As senhas precisam ser iguais"
                      )
                : yup.string()
            : yup
                  .string()
                  .required("Senha obrigatória")
                  .min(12, "Mínimo 12 digitos"),
        confirmPassword: id
            ? password.length > 0
                ? yup
                      .string()
                      .matches(
                          /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])/,
                          "A senha deve ter no minimo 12 caracteres, letras minusculas e maiusculas e caracteres especiais."
                      )
                      .min(12, "Mínimo 12 digitos")
                      .oneOf(
                          [yup.ref("password"), null],
                          "As senhas precisam ser iguais"
                      )
                : yup.string()
            : yup
                  .string()
                  .required("Confirme a senha")
                  .matches(
                      /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[$*&@#])/,
                      "A senha deve ter no minimo 12 caracteres, letras minusculas e maiusculas e caracteres especiais."
                  )
                  .min(12, "Mínimo 12 digitos")
                  .oneOf(
                      [yup.ref("password"), null],
                      "As senhas precisam ser iguais"
                  ),
        department: yup.string().required("Selecione um departamento"),
        cargo: yup.string().required("Selecione um cargo"),
    });

    // ? initial load
    useEffect(() => {
        async function getUser() {
            if (id) {
                setLoading(true);
                try {
                    const { data } = await api.get(`users/${id}`);
                    console.log(data);
                    setValue("fullname", data.fullname);
                    setValue("email", data.email);
                    setValue("department", data.department);
                    setValue("cargo", data.cargo);
                    setCargo(data.department);
                    setTimeout(() => {
                        setLoading(false);
                    }, 1000);
                } catch {
                    setLoading(false);
                }
            }
        }

        getUser();
    }, []);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<RegistrationProps>({
        resolver: yupResolver(schema),
    });

    async function handleRegisterClient(data: RegistrationProps) {
        setIsLoading(true);
        const dataRegistration = {
            fullname: data.fullname,
            email: data.email,
            password: data.password,
            department: data.department,
            cargo: data.cargo,
        } as any;
        try {
            let response;
            if (id) {
                if (!dataRegistration.password) {
                    delete dataRegistration.password;
                }
                response = await api.put(
                    `users/update/${id}`,
                    dataRegistration
                );
            } else {
                response = await api.post("users/add", dataRegistration);
            }

            if (response.data.status === "Error") {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível realizar seu cadastro",
                    text: response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
            } else {
                modal.setCustomModal({
                    status: true,
                    icon: "success",
                    title: "Usuário cadastrado/editado com sucesso!",
                    text: "Agora você pode acessá-lo na área de usuários",
                    cancelButton: "",
                    confirmButton: "Ok",
                });
            }
            setIsLoading(false);
        } catch (e: any) {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Um erro inesperado aconteceu",
                text: e.response.data.message,
                cancelButton: "",
                confirmButton: "",
            });
            setIsLoading(false);
        }
    }

    return (
        <>
            {!loading ? (
                <>
                    <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                        <div className="md:grid md:grid-cols-3 md:gap-6">
                            <div className="md:col-span-1">
                                <h3 className="text-lg font-medium leading-6 text-gray-900">
                                    Cadastro de usuários
                                </h3>
                                <p className="mt-1 text-sm text-gray-500">
                                    Área de cadastro de usuário
                                </p>
                            </div>
                            <div className="mt-5 md:mt-0 md:col-span-2">
                                <form
                                    action="#"
                                    method="POST"
                                    id="userRegisterForm"
                                    autoComplete="off"
                                    onSubmit={handleSubmit(
                                        handleRegisterClient
                                    )}
                                >
                                    <div className="hidden">
                                        <input
                                            type="email"
                                            autoComplete="email"
                                        />
                                        <input
                                            type="password"
                                            autoComplete="password"
                                        />
                                    </div>
                                    <div className="grid grid-cols-6 gap-6">
                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="fullname"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Nome
                                            </label>
                                            <input
                                                {...register("fullname")}
                                                type="text"
                                                name="fullname"
                                                id="fullname"
                                                autoComplete="given-name"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "fullname",
                                                        e.target.value
                                                    );
                                                }}
                                            />
                                            {errors.fullname && (
                                                <span className="text-mainDarkRed">
                                                    {errors.fullname.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="email"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                E-mail
                                            </label>
                                            <input
                                                {...register("email")}
                                                type="email"
                                                name="email"
                                                id="email"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "email",
                                                        e.target.value
                                                    );
                                                }}
                                                autoComplete="new-password"
                                            />
                                            {errors.email && (
                                                <span className="text-mainDarkRed">
                                                    {errors.email.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="password"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                {id ? "Mudar a senha" : "Senha"}
                                            </label>
                                            <input
                                                {...register("password")}
                                                type="password"
                                                name="password"
                                                id="password"
                                                autoComplete="new-password"
                                                onChange={(e) =>
                                                    setPassword(e.target.value)
                                                }
                                                minLength={12}
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                            />
                                            {errors.password && (
                                                <span className="text-mainDarkRed">
                                                    {errors.password.message}
                                                </span>
                                            )}
                                        </div>

                                        {
                                            <div className="col-span-6 sm:col-span-3">
                                                <label
                                                    htmlFor="confirmPassword"
                                                    className="block text-sm font-medium text-gray-700"
                                                >
                                                    {id
                                                        ? "Confirme sua nova senha"
                                                        : "Confirme sua senha"}
                                                </label>
                                                <input
                                                    {...register(
                                                        "confirmPassword"
                                                    )}
                                                    type="password"
                                                    minLength={12}
                                                    name="confirmPassword"
                                                    id="confirmPassword"
                                                    autoComplete="new-password"
                                                    className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                />
                                                {errors.confirmPassword && (
                                                    <span className="text-mainDarkRed">
                                                        {
                                                            errors
                                                                .confirmPassword
                                                                .message
                                                        }
                                                    </span>
                                                )}
                                            </div>
                                        }

                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="department"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Departamento
                                            </label>
                                            <select
                                                {...register("department")}
                                                id="department"
                                                name="department"
                                                autoComplete="department-name"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setCargo(e.target.value);
                                                    setValue(
                                                        "department",
                                                        e.target.value
                                                    );
                                                    setValue("cargo", "");
                                                }}
                                            >
                                                <option>Operação</option>
                                                <option>Programação</option>
                                                <option>Suporte</option>
                                            </select>
                                            {errors.department && (
                                                <span className="text-mainDarkRed">
                                                    {errors.department.message}
                                                </span>
                                            )}
                                        </div>

                                        <div className="col-span-6 sm:col-span-3">
                                            <label
                                                htmlFor="cargo"
                                                className="block text-sm font-medium text-gray-700"
                                            >
                                                Cargo
                                            </label>
                                            <select
                                                {...register("cargo")}
                                                id="cargo"
                                                name="cargo"
                                                autoComplete="cargo"
                                                className="mt-1 block w-full border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                                onChange={(e) => {
                                                    setValue(
                                                        "cargo",
                                                        e.target.value
                                                    );
                                                }}
                                            >
                                                {/* Operação */}
                                                {cargo === "Operação" && (
                                                    <>
                                                        <option>
                                                            Inspetor
                                                        </option>
                                                        <option>
                                                            Operação
                                                        </option>
                                                    </>
                                                )}
                                                {/* Programação */}
                                                {cargo === "Programação" && (
                                                    <option>Programação</option>
                                                )}
                                                {/* Suporte */}
                                                {cargo === "Suporte" && (
                                                    <>
                                                        <option>
                                                            Administrador
                                                        </option>
                                                        <option>
                                                            Gestor de contrato
                                                        </option>
                                                    </>
                                                )}
                                            </select>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div className="mt-6 w-full flex justify-center md:justify-end gap-3">
                            <div
                                className="w-full md:w-32"
                                onClick={() => history.goBack()}
                            >
                                <SecondaryButton title="Voltar" />
                            </div>
                            <div className="w-full md:w-32">
                                <PrimaryButton
                                    title="Salvar"
                                    form="userRegisterForm"
                                    isLoading={isLoading}
                                />
                            </div>
                        </div>
                    </div>
                    <AlertModal
                        type={modal.customModal.icon}
                        title={modal.customModal.title}
                        description={modal.customModal.text}
                        isOpen={modal.customModal.status}
                        setIsOpen={modal.handleCustomModalClose}
                        confirmButton={modal.customModal.confirmButton}
                        path="/usuarios"
                    />
                </>
            ) : (
                <div className="w-full h-56 flex justify-center items-center">
                    <ReactLoading color="#4d4d4d" type="spinningBubbles" />
                </div>
            )}
        </>
    );
}
