import PrimaryButton from "../../components/Button/PrimaryButton";
import SecondaryButton from "../../components/Button/SecondaryButton";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import history from "../../services/history";

import { yupResolver } from "@hookform/resolvers/yup/dist/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
import { AlertModal } from "../../components/AlertModal";
import { useCallback, useContext, useEffect, useState } from "react";
import { DefectProps } from "../../types/Defects";
import InfoContext from "../../context/InfoContext";
import { Context } from "../../context/AuthContext";
import { Link, useParams } from "react-router-dom";

interface RegistrationProps {
    fullname: string;
    email: string;
    password: string;
    confirmPassword: string;
    department: string;
    cargo: string;

    defect_id: string;
    product_id: string;
}

export default function DefectsToProducts() {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [defectList, setDefectList] = useState<DefectProps[]>();
    const [defectListLinkedWithProduct, setDefectListLinkedWithProduct] = useState<DefectProps[]>();

    const modal = useCustomModal();

    async function handleRegisterDefectToProduct(
        defect_id: string,
        product_id: string
    ) {
        setIsLoading(true);
        const dataRegistration = {
            defect_id: defect_id,
            product_id: product_id,
        };
        try {

            const response = await api.post(
                "products/relation/defect/add",
                dataRegistration
            );
            if (response.data.status === "Error") {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível realizar seu cadastro",
                    text: response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
            }

            setIsLoading(false);
        } catch {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Um erro inesperado aconteceu",
                text: "Não foi possível realizar o cadastro",
                cancelButton: "",
                confirmButton: "",
            });
            setIsLoading(false);
        }
    }

    async function handleRegisterDefectToProductRemove(
        defect_id: string,
        product_id: string
    ) {
        const data = { idProduct: product_id, idDefect: defect_id };

        try {
            const response = await api.post("products/relation/product/defect/remove", data);

            if (response.data.status === "Error") {
                modal.setCustomModal({
                    status: true,
                    icon: "error",
                    title: "Não foi possível remover o defeito da listagem",
                    text: response.data.message,
                    cancelButton: "",
                    confirmButton: "",
                });
            }

            setIsLoading(false);

        } catch (erro: any) {
            modal.setCustomModal({
                status: true,
                icon: "error",
                title: "Um erro inesperado aconteceu",
                text: "Não foi possível realizar o cadastro",
                cancelButton: "",
                confirmButton: "",
            });

            setIsLoading(false);
        }

    }


    const { handleLogOut, user } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);

    let { id }: { id: string } = useParams();

    useEffect(() => {
        async function getDefects() {
            try {

                const { data } = await api.get(`defects?limit=100`);

                setDefectList(data.list);
            } catch {
                handleLogOut();
            }
        }

        getDefects();
        // defectsConnetctedToProducts();
    }, [id, reloadData]);

    const defectsConnetctedToProducts = useCallback(async () => {
        try {
            const { data } = await api.get(`defects/defect/by/product?product_id=${id}`);
            console.log(data);

            data.list.forEach((valor: any) => {
                setValue(
                    valor.defect_id,
                    true
                )
            });
            // setDefectListLinkedWithProduct(data.list)
        } catch (err: any) {
            console.log(err);
            handleLogOut();

        }
    }, [id]);

    useEffect(() => {
        defectsConnetctedToProducts();
    }, [id]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm<any>();


    return (
        <>
            <div className="bg-white shadow px-4 py-5 sm:rounded-lg sm:p-6">
                <div className="md:grid md:grid-cols-3 md:gap-6">
                    <div className="md:col-span-1">
                        <h3 className="text-lg font-medium leading-6 text-gray-900">
                            Vincular defeitos à produtos
                        </h3>
                        <p className="mt-1 text-sm text-gray-500">
                            Área para vincular defeitos à produtos
                        </p>
                    </div>
                    <div className="mt-5 md:mt-0 md:col-span-2">
                        <form action="#" method="POST" id="userRegisterForm">
                            <div className="block text-lg font-bold text-gray-700">
                                Lista de defeitos
                            </div>
                            <div className="grid grid-cols-6 gap-6">
                                {defectList &&
                                    defectList.map((item) => (
                                        <div
                                            className="col-span-6 sm:col-span-6 lg:col-span-2"
                                            key={item.id}
                                        >
                                            <div className="relative flex items-center h-10">
                                                <div className="flex items-center h-5">
                                                    <input
                                                        {...register(`${item.id}`)}
                                                        id="accept_term"
                                                        aria-describedby="accept_term"
                                                        name="accept_term"
                                                        type="checkbox"
                                                        className="focus:ring-mainDarkRed h-4 w-4 text-indigo-600 border-gray-300 rounded"

                                                        onChange={(e) => {
                                                            console.log();
                                                            if (e.target.checked) {
                                                                handleRegisterDefectToProduct(
                                                                    item.id,
                                                                    id
                                                                )
                                                            } else {
                                                                handleRegisterDefectToProductRemove(
                                                                    item.id,
                                                                    id
                                                                )
                                                            }
                                                        }}
                                                    />
                                                </div>
                                                <div className="ml-3 text-sm">
                                                    <p className=" text-sm font-medium text-gray-700">
                                                        {item.name_defect}
                                                    </p>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                            </div>
                        </form>
                    </div>
                </div>
                <div className="mt-6 w-full flex justify-center md:justify-end gap-3">
                    <div
                        className="w-full md:w-32"
                        onClick={() => history.goBack()}
                    >
                        <SecondaryButton title="Voltar" />
                    </div>
                    {/* <div className="w-full md:w-32">
                        <PrimaryButton
                            title="Salvar"
                            form="userRegisterForm"
                            isLoading={isLoading}
                        />
                    </div> */}
                </div>
            </div>
            <AlertModal
                type={modal.customModal.icon}
                title={modal.customModal.title}
                description={modal.customModal.text}
                isOpen={modal.customModal.status}
                setIsOpen={modal.handleCustomModalClose}
                confirmButton={modal.customModal.confirmButton}
                path="/usuarios"
            />
        </>
    );
}
