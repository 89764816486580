
import moment from "moment";
import { useCallback, useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import PrimaryButton from "../../components/Button/PrimaryButton";
import LoadingTable from "../../components/LoadingTable";
import { Pagination } from "../../components/Pagination";
import SearchBar from "../../components/SearchBar";
import SolicitationsTable from "../../components/Table/SolicitationsTable";
import { Context } from "../../context/AuthContext";
import InfoContext from "../../context/InfoContext";
import api from "../../services/api";
import { SolicitationsProps } from "../../types/Solicitations";

export default function Dashboard() {
    const [solicitationList, setSolicitationList] = useState<
        SolicitationsProps[]
    >([]);

    const { handleLogOut, user } = useContext(Context);
    const { reloadData, setReloadData } = useContext(InfoContext);

    const [isLoading, setIsLoading] = useState<boolean>(false);

    const [page, setPage] = useState(1);
    const [lastPage, setLastPage] = useState(1);
    const [limit, setLimit] = useState(1);
    const [totalRegister, setTotalRegister] = useState(1);
    const [dateFilter, setDateFilter] = useState<string>("");
    const [search, setSearch] = useState<string>("");
    const [searchDateStart, setSearchDateStart] = useState<any>("");
    const [searchDateEnd, setSearchDateEnd] = useState<any>("");
    console.log(
        "date",
        searchDateStart && searchDateEnd !== ""
            ? moment(searchDateStart).format("DD/MM/YYYY") +
            "-" +
            moment(searchDateEnd).format("DD/MM/YYYY")
            : ""
    );

    let { id }: { id: string } = useParams();

    const getSolicitations = useCallback(async () => {
        try {
            const { data } = await api.get(
                `solicitations?page=${page}&query=${search}&days=${searchDateStart && searchDateEnd !== ""
                    ? moment(searchDateStart).format("DD/MM/YYYY") +
                    "-" +
                    moment(searchDateEnd).format("DD/MM/YYYY")
                    : ""
                }`
            );
            // console.log("dados->", data);
            setPage(data.currentPage);
            setLastPage(data.lastPage);
            setTotalRegister(data.totalRegister);
            setSolicitationList(data.list);
            setIsLoading(true);
        } catch {
            handleLogOut();
        }
    }, [page, searchDateStart, searchDateEnd, search]);

    useEffect(() => {
        getSolicitations();
    }, [id, reloadData, page, searchDateStart, searchDateEnd, search]);

    return (
        <div>
            <div className="flex items-center justify-between">
                <h1 className="text-2xl font-bold">Solicitações</h1>
            </div>
            <div className="flex flex-wrap justify-between mt-4">
                <div className="flex flex-wrap">
                    <div className="mb-1">
                        <div className="block text-sm font-medium text-gray-700">
                            Pesquise por nome
                        </div>
                        <SearchBar
                            search={search}
                            setSearch={setSearch}
                            placeholder="Nome da fabrica"
                        />
                    </div>
                    <div className="flex flex-row flex-wrap sm:ml-8">
                        <div className="">
                            <label
                                htmlFor="date_call"
                                className="block text-sm font-medium text-gray-700"
                            >
                                Pesquise por data
                            </label>
                            <div className="flex flex-wrap justify-between">
                                <div className="flex flex-wrap items-center gap-3 mb-2 mr-10">
                                    <input
                                        type="date"
                                        name="date_call"
                                        id="date_call"
                                        autoComplete="given-date"
                                        onChange={(e) =>
                                            setSearchDateStart(e.target.value)
                                        }
                                        className="block px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm w-36 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                    <div className="">até</div>
                                    <input
                                        type="date"
                                        name="date_call"
                                        id="date_call"
                                        min={searchDateStart}
                                        autoComplete="given-date"
                                        onChange={(e) =>
                                            setSearchDateEnd(e.target.value)
                                        }
                                        className="block px-3 py-2 mt-1 border border-gray-300 rounded-md shadow-sm w-36 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt-6">
                    <Link to="/solicitacao-formulario">
                        <PrimaryButton title="Nova solicitação" />
                    </Link>
                </div>
            </div>
            {isLoading ? (
                <>
                    {solicitationList && solicitationList.length ? (
                        <>
                            <SolicitationsTable list={solicitationList} />
                            <div className="w-full h-8 " />
                            <Pagination
                                totalPage={lastPage}
                                page={page}
                                setPage={setPage}
                                path={`solicitations`}
                            />
                        </>
                    ) : (
                        <main className="flex flex-col justify-center flex-grow w-full px-4 mx-auto max-w-7xl sm:px-6 lg:px-8">
                            <div className="py-16">
                                <div className="text-center">
                                    <p className="text-sm font-semibold tracking-wide text-indigo-600 uppercase"></p>
                                    <h1 className="mt-2 text-4xl font-extrabold tracking-tight text-gray-900 sm:text-5xl">
                                        No momento não temos solicitações
                                        cadastradas
                                    </h1>
                                    <p className="mt-2 text-base text-gray-500"></p>
                                </div>
                            </div>
                        </main>
                    )}
                </>
            ) : (
                <LoadingTable />
            )}
        </div>
    );
}
