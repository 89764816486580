interface SearchBarProps {
    search: string;
    setSearch: Function;
    placeholder?: string;
    width?: string;
}
export default function SearchBar({
    search,
    setSearch,
    placeholder,
    width,
}: SearchBarProps) {
    return (
        <div className="col-span-6 sm:col-span-3">
            <input
                type="text"
                value={search}
                autoComplete="search"
                placeholder={placeholder ? placeholder : "Pesquisar"}
                className={`mt-1 block ${
                    width ? width : "w-44"
                } border border-gray-300 rounded-md shadow-sm py-2 px-3 focus:outline-none focus:ring-mainDarkRed focus:border-mainDarkRed sm:text-sm`}
                onChange={(e) => {
                    setSearch(e.target.value);
                }}
            />
        </div>
    );
}
