import { Link, useParams } from "react-router-dom";
import { useContext, useState } from "react";
import InfoContext from "../../context/InfoContext";
import { useCustomModal } from "../../hooks/useCustomModal";
import api from "../../services/api";
import { FactoryProps } from "../../types/Factory";
import { ActionModal } from "../ActionModal";
import EmptyList from "../EmptyList";
import "./styles.css";

import Swal from "sweetalert2";
import {
    MdOutlineAssignmentReturned,
    MdOutlineAssignmentTurnedIn,
    MdOutlineLocationOn,
    MdOutlineCancel,
    MdEdit,
    MdInfoOutline,
    MdOutlineAssignment,
    MdOutlineHighlightOff,
    MdOutlineContactSupport,
} from "react-icons/md";

import {
    FaSpinner,
    FaPencilAlt,
    FaFilePdf,
    FaInfoCircle,
    FaFileAlt,
} from "react-icons/fa";
import { AiFillDelete } from "react-icons/ai";

type TableProps = {
    list: FactoryProps[];
};

export default function FactoryTable({ list }: TableProps) {
    const modal = useCustomModal();
    const [itemId, setItemId] = useState<string>("");
    const { reloadData, setReloadData } = useContext(InfoContext);

    async function removeUser(id: string) {
        await api.delete(`factories/delete/${id}`);
        // await setIsOpen(false);
        Swal.fire({
            icon: "success",
            title: "Sucesso",
            showDenyButton: true,
            showCloseButton: false,
            showConfirmButton: false,
            denyButtonText: `Fechar`,
            text: "Fábrica deletada com sucesso!",
        });
        setReloadData(!reloadData);
    }

    return (
        <>
            <body className="flex items-center justify-center">
                <div className="container hidden sm:block">
                    <table className="w-full flex flex-row flex-no-wrap sm:bg-white rounded-lg overflow-hidden sm:shadow-lg my-5">
                        <thead className="text-white">
                            {list &&
                                list.map((item) => (
                                    <tr className="bg-mainGray flex flex-col flex-no wrap sm:table-row rounded-l-lg sm:rounded-none mb-2 sm:mb-0">
                                        <th className="p-3 text-left">
                                            Código de cadastro
                                        </th>
                                        <th className="p-3 text-left">Nome</th>
                                        <th className="p-3 text-left">CNPJ</th>
                                        <th className="p-3 text-left">
                                            E-mail
                                        </th>
                                        <th className="p-3 text-left">
                                            Termo de aceite
                                        </th>
                                        <th className="p-3 text-left w-[110px]">
                                            Ações
                                        </th>
                                    </tr>
                                ))}
                        </thead>
                        <tbody className="flex-1 sm:flex-none">
                            {list.length > 0 ? (
                                list.map((item) => (
                                    <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0 bg-white">
                                        <td className="border-grey-light border p-3">
                                            {item.factoryCode}
                                        </td>
                                        <td className="border-grey-light border p-3">
                                            {item.fullname}
                                        </td>
                                        <td className="border-grey-light border p-3 truncate">
                                            {item.cnpj}
                                        </td>
                                        <td className="border-grey-light border p-3 truncate">
                                            {item.email}
                                        </td>
                                        <td className="border-grey-light border p-3 truncate">
                                            {item.accept_term ? "Sim" : "Não"}
                                        </td>
                                        <td className="border-grey-light border p-3 text-mainGray hover:font-medium">
                                            <div className="flex">
                                                <Link
                                                    className="cursor-pointer text-mainGray hover:text-mainDarkRed transition-all duration-500"
                                                    to={`/fabricas-editar/${item.id}`}
                                                >
                                                    <MdOutlineAssignment
                                                        size={"1.3em"}
                                                    />
                                                </Link>
                                                &nbsp; &nbsp;
                                                <a
                                                    className="cursor-pointer text-mainGray hover:text-mainDarkRed transition-all duration-500"
                                                    onClick={() => {
                                                        modal.setCustomModal({
                                                            status: true,
                                                            icon: "alert",
                                                            title: "Excluir!",
                                                            text: "Você tem certeza que deseja excluir esse fábrica?",
                                                            cancelButton:
                                                                "Cancelar",
                                                            confirmButton: "",
                                                        });
                                                        setItemId(
                                                            item.id.toString()
                                                        );
                                                    }}
                                                >
                                                    <MdOutlineHighlightOff
                                                        size={"1.3em"}
                                                    />
                                                </a>
                                            </div>
                                        </td>
                                    </tr>
                                ))
                            ) : (
                                <EmptyList />
                            )}
                        </tbody>
                    </table>
                </div>
                <ActionModal
                    type={modal.customModal.icon}
                    title={modal.customModal.title}
                    description={modal.customModal.text}
                    isOpen={modal.customModal.status}
                    setIsOpen={modal.handleCustomModalClose}
                    action={removeUser}
                    itemId={itemId}
                />
            </body>
            <div className="mb-4">
                {list &&
                    list.map((item) => (
                        <div className="flex justify-center h-full rounded-xl border border-gray-200 bg-white sm:hidden mt-4 overflow-hidden ">
                            <div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Codigo de cadastro
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Nome
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    CNPJ
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    E-mail
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Termo de aceite
                                </div>
                                <div className="h-12 text-left flex justify-start items-center px-2 bg-mainGray text-white font-semibold border-t ">
                                    Ações
                                </div>
                            </div>
                            <div className="w-full overflow-x-auto">
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    {item.factoryCode}
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    {item.fullname}
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    {item.cnpj}
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    {item.email}
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    {item.accept_term ? "Sim" : "Não"}
                                </div>
                                <div className="border-grey-light border-t h-12 flex justify-start items-center px-2">
                                    <div className="flex">
                                        <Link
                                            className="cursor-pointer"
                                            to={`/fabricas-editar/${item.id}`}
                                        >
                                            <MdOutlineAssignment
                                                size={"1.3em"}
                                            />
                                        </Link>
                                        &nbsp; &nbsp;
                                        <a
                                            className="cursor-pointer"
                                            onClick={() => {
                                                modal.setCustomModal({
                                                    status: true,
                                                    icon: "alert",
                                                    title: "Excluir!",
                                                    text: "Você tem certeza que deseja excluir esse fábrica?",
                                                    cancelButton: "Cancelar",
                                                    confirmButton: "",
                                                });
                                                setItemId(item.id.toString());
                                            }}
                                        >
                                            <MdOutlineHighlightOff
                                                size={"1.3em"}
                                            />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
            </div>
        </>
    );
}
