import { Fragment, ReactNode, useContext, useState } from "react";
import { Dialog, Menu, Transition } from "@headlessui/react";
import {
    BellIcon,
    CalendarIcon,
    ChartBarIcon,
    FolderIcon,
    HomeIcon,
    InboxIcon,
    MenuAlt2Icon,
    UsersIcon,
    XIcon,
    ChevronDownIcon,
    ViewListIcon,
    DocumentSearchIcon,
    DocumentReportIcon,
    UserGroupIcon,
    PencilAltIcon,
} from "@heroicons/react/outline";
import { SearchIcon } from "@heroicons/react/solid";

import { Link, Router, useLocation } from "react-router-dom";

import logo from "../../assets/logo.png";
import { Context } from "../../context/AuthContext";

type LayoutProps = {
    component: ReactNode;
};

function classNames(...classes: any) {
    return classes.filter(Boolean).join(" ");
}

export default function Layout({ component }: LayoutProps) {
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const { handleLogOut, user } = useContext(Context);

    const location = useLocation();

    const userNavigation = [
        // { name: "Seu Perfil", href: "#" },
        // { name: "Configurações", href: "#" },
        {
            name: "Sair",
            href: "#",
            action: () => {
                handleLogOut();
            },
        },
    ];

    const navigation = [
        {
            name: "Solicitações",
            href: "/",
            icon: DocumentReportIcon,
            current:
                location.pathname === "/" ||
                (location.pathname.startsWith("/solic") && true),
        },
        {
            name: "Relatórios",
            href: "/reports",
            icon: ChartBarIcon,
            current:
                location.pathname === "/reports" ||
                (location.pathname.startsWith("/reports") && true),
        },
        {
            name: "Perguntas Adicionais",
            href: "/extraQuestions",
            icon: ViewListIcon,
            current:
                location.pathname.startsWith("/extraQ") ||
                (location.pathname.startsWith("/extraQ") && true),
        },
        {
            name: "Produtos",
            href: "/produtos",
            icon: FolderIcon,
            current:
                location.pathname.startsWith("/produt") ||
                (location.pathname.startsWith("/questi") && true),
        },
        {
            name: "Conjunto ou Kit de Produtos",
            href: "/conjuntos-produtos",
            icon: CalendarIcon,
            current: location.pathname.startsWith("/conjunto") && true,
        },
        {
            name: "Defeitos",
            href: "/defeitos",
            icon: DocumentSearchIcon,
            current: location.pathname.startsWith("/defeit") && true,
        },
        {
            name: "Auditor",
            href: "/auditores",
            icon: PencilAltIcon,
            current: location.pathname.startsWith("/audit") && true,
        },
        {
            name: "Fábricas",
            href: "/fabricas",
            icon: ChartBarIcon,
            current: location.pathname.startsWith("/fabri") && true,
        },
        {
            name: "Clientes",
            href: "/clientes",
            icon: UsersIcon,
            current: location.pathname.startsWith("/clien") && true,
        },
        {
            name: "Usuários",
            href: "/usuarios",
            icon: UserGroupIcon,
            current: location.pathname.startsWith("/usuario") && true,
        },
    ];

    return (
        <>
            <div>
                <Transition.Root show={sidebarOpen} as={Fragment}>
                    <Dialog
                        as="div"
                        className="relative z-40 md:hidden"
                        onClose={setSidebarOpen}
                    >
                        <Transition.Child
                            as={Fragment}
                            enter="transition-opacity ease-linear duration-300"
                            enterFrom="opacity-0"
                            enterTo="opacity-100"
                            leave="transition-opacity ease-linear duration-300"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <div className="fixed inset-0 bg-gray-600 bg-opacity-75" />
                        </Transition.Child>

                        <div className="fixed inset-0 flex z-40">
                            <Transition.Child
                                as={Fragment}
                                enter="transition ease-in-out duration-300 transform"
                                enterFrom="-translate-x-full"
                                enterTo="translate-x-0"
                                leave="transition ease-in-out duration-300 transform"
                                leaveFrom="translate-x-0"
                                leaveTo="-translate-x-full"
                            >
                                <Dialog.Panel className="relative flex-1 flex flex-col max-w-xs w-full pt-5 pb-4 bg-white">
                                    <Transition.Child
                                        as={Fragment}
                                        enter="ease-in-out duration-300"
                                        enterFrom="opacity-0"
                                        enterTo="opacity-100"
                                        leave="ease-in-out duration-300"
                                        leaveFrom="opacity-100"
                                        leaveTo="opacity-0"
                                    >
                                        <div className="absolute top-0 right-0 -mr-12 pt-2">
                                            <button
                                                type="button"
                                                className="ml-1 flex items-center justify-center h-10 w-10 rounded-full focus:outline-none focus:ring-2 focus:ring-inset focus:ring-white"
                                                onClick={() =>
                                                    setSidebarOpen(false)
                                                }
                                            >
                                                <span className="sr-only">
                                                    Close sidebar
                                                </span>
                                                <XIcon
                                                    className="h-6 w-6 text-white"
                                                    aria-hidden="true"
                                                />
                                            </button>
                                        </div>
                                    </Transition.Child>
                                    <div className="flex-shrink-0 flex items-center px-4">
                                        <img
                                            className="h-28 w-auto"
                                            src={logo}
                                            alt="bureau logo"
                                        />
                                    </div>
                                    <div className="mt-5 flex-1 h-0 overflow-y-auto">
                                        <nav className="px-2 space-y-1">
                                            {navigation.map((item) => (
                                                <Link
                                                    to={item.href}
                                                    key={item.name}
                                                    className={classNames(
                                                        item.current
                                                            ? "bg-mainDarkRed text-white"
                                                            : "text-gray-300 hover:bg-mainGray hover:text-white",
                                                        "group flex items-center px-2 py-2 text-base font-medium rounded-md"
                                                    )}
                                                >
                                                    <item.icon
                                                        className={classNames(
                                                            item.current
                                                                ? "text-gray-300"
                                                                : "text-gray-400 group-hover:text-gray-300",
                                                            "mr-4 flex-shrink-0 h-6 w-6"
                                                        )}
                                                        aria-hidden="true"
                                                    />
                                                    {item.name}
                                                </Link>
                                            ))}
                                        </nav>
                                    </div>
                                </Dialog.Panel>
                            </Transition.Child>
                            <div
                                className="flex-shrink-0 w-14"
                                aria-hidden="true"
                            >
                                {/* Dummy element to force sidebar to shrink to fit close icon */}
                            </div>
                        </div>
                    </Dialog>
                </Transition.Root>

                {/* Static sidebar for desktop */}
                <div className="hidden md:flex md:w-64 md:flex-col md:fixed md:inset-y-0">
                    {/* Sidebar component, swap this element with another sidebar if you like */}
                    <div className="flex-1 flex flex-col min-h-0 bg-white">
                        <div className="flex items-center justify-center h-32 flex-shrink-0 px-4 bg-white">
                            <img
                                className="h-28 w-auto"
                                src={logo}
                                alt="bureau logo"
                            />
                        </div>
                        <div className="flex-1 flex flex-col overflow-y-auto">
                            <nav className="flex-1 px-2 py-4 space-y-1">
                                {navigation.map((item) => (
                                    <Link
                                        key={item.name}
                                        to={item.href}
                                        className={classNames(
                                            item.current
                                                ? "border-l-4 border-mainDarkRed text-mainDarkRed"
                                                : "text-gray-500 hover:bg-mainGray hover:text-white rounded-md",
                                            "group flex items-center px-2 py-2 text-sm font-medium "
                                        )}
                                    >
                                        <item.icon
                                            className={classNames(
                                                item.current
                                                    ? "text-mainDarkRed"
                                                    : "text-gray-500 group-hover:text-gray-300 ",
                                                "mr-3 flex-shrink-0 h-6 w-6"
                                            )}
                                            aria-hidden="true"
                                        />
                                        {item.name}
                                    </Link>
                                ))}
                            </nav>
                        </div>
                    </div>
                </div>
                <div className="md:pl-64 flex flex-col">
                    <div className="top-0 z-10 flex-shrink-0 flex h-16 bg-white relative shadow w-full">
                        <button
                            type="button"
                            className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500 md:hidden"
                            onClick={() => setSidebarOpen(true)}
                        >
                            <span className="sr-only">Open sidebar</span>
                            <MenuAlt2Icon
                                className="h-6 w-6"
                                aria-hidden="true"
                            />
                        </button>
                        <div className="flex-1 px-4 flex justify-between">
                            <div className="flex-1 flex"></div>
                            <div className="ml-4 flex items-center md:ml-6">
                                {/* Profile dropdown */}
                                <Menu as="div" className="ml-3 relative">
                                    <div>
                                        <Menu.Button className="max-w-xs bg-white flex items-center gap-3 text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500">
                                            <span className="sr-only">
                                                Open user menu
                                            </span>
                                            <p>{user?.fullname}</p>
                                            <ChevronDownIcon className="h-4 w-4" />
                                            {/* <img
                                                className="h-8 w-8 rounded-full"
                                                src="https://images.unsplash.com/photo-1472099645785-5658abf4ff4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                                                alt=""
                                            /> */}
                                        </Menu.Button>
                                    </div>
                                    <Transition
                                        as={Fragment}
                                        enter="transition ease-out duration-100"
                                        enterFrom="transform opacity-0 scale-95"
                                        enterTo="transform opacity-100 scale-100"
                                        leave="transition ease-in duration-75"
                                        leaveFrom="transform opacity-100 scale-100"
                                        leaveTo="transform opacity-0 scale-95"
                                    >
                                        <Menu.Items className="origin-top-right absolute right-0 mt-2 w-48 rounded-md shadow-lg py-1 bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
                                            {userNavigation.map((item) => (
                                                <Menu.Item key={item.name}>
                                                    {({ active }: any) => (
                                                        <a
                                                            href={item.href}
                                                            className={classNames(
                                                                active
                                                                    ? "bg-gray-100"
                                                                    : "",
                                                                "block px-4 py-2 text-sm text-gray-700"
                                                            )}
                                                            onClick={
                                                                item?.action
                                                            }
                                                        >
                                                            {item.name}
                                                        </a>
                                                    )}
                                                </Menu.Item>
                                            ))}
                                        </Menu.Items>
                                    </Transition>
                                </Menu>
                            </div>
                        </div>
                    </div>

                    <main className="flex-1">
                        <div className="py-6">
                            <div className="max-w-7xl mx-auto px-4 sm:px-6 md:px-8 overflow-x-auto">
                                {/* Replace with your content */}
                                <div className="py-4">{component}</div>
                                {/* /End replace */}
                            </div>
                        </div>
                    </main>
                </div>
            </div>
        </>
    );
}
